import React from "react";

import { type WidgetType } from "legacy/constants/WidgetConstants";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "./BaseWidget";
import {
  customComponentId,
  isCustomComponentType,
} from "./CustomComponentTypeUtil";

class UnregisteredCustomWidget extends BaseWidget<
  UnregisteredCustomWidgetProps,
  WidgetState
> {
  getPageView() {
    let description: string;
    if (
      this.props.originalType &&
      isCustomComponentType(this.props.originalType)
    ) {
      const id = customComponentId(this.props.originalType);
      description = `custom component with id ${id}`;
    } else {
      const type = this.props.originalType ?? this.props.type;
      description = `component of type ${type}`;
    }
    return (
      <div>
        This application is missing a {description}. You can either delete this
        component from the page, or register the matching type using the
        Superblocks CLI.
      </div>
    );
  }

  getWidgetType(): WidgetType {
    return "UNREGISTERED_CUSTOM_WIDGET";
  }
}

export interface UnregisteredCustomWidgetProps extends WidgetPropsRuntime {
  originalType?: string;
}

export default UnregisteredCustomWidget;
