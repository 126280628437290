import { debounce } from "lodash";
import React from "react";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import BaseWidget, { WidgetState } from "../BaseWidget";
import withMeta from "../withMeta";
import { CodeComponentWithManagedLayout } from "./CodeEditor";
import CodeWidgetPropertyCategories from "./CodeWidgetPropertyCategories";
import { CodeWidgetProps } from "./types";
import type { DerivedPropertiesMap } from "../Factory";

class CodeWidget extends BaseWidget<CodeWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<CodeWidgetProps>[]
    | undefined {
    return CodeWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      stringValue: "initialValue",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      _parsedInfo: /*javascript*/ `{{(() => {
        const input = this.stringValue ?? this.initialValue;
        let parsedValue;
        let isValid = true;
        try {
          if (this.mode === "application/hjson") {
            parsedValue = HJSON.parse(input);
          } else if (this.mode === "application/json") {
            parsedValue = JSON.parse(input);
          } else {
            parsedValue = input;
          }
        } catch (e) {
          isValid = false;
        }
        if (this.isRequired && !input) isValid = false;
        if (this.customValidationRule === "false") {
          isValid = false;
        }
        return { parsedValue, isValid };
      })()}}`,
      parsedValue: /*javascript*/ `{{this._parsedInfo.parsedValue}}`,
      isValid: /*javascript*/ `{{this._parsedInfo.isValid}}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      stringValue: undefined,
    };
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      initialValue: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
    };
  }

  getPageView() {
    return (
      <CodeComponentWithManagedLayout
        {...this.props}
        onChange={this.onChange}
      />
    );
  }

  componentWillUnmount() {
    this.onChangeDebounced.flush();
  }

  onChangeDebounced = debounce((value: string) => {
    if (this.props.onChange) {
      this.runEventHandlers?.({
        steps: this.props.onChange,
        type: EventType.ON_TEXT_CHANGE,
        additionalNamedArguments: {},
      });
    }
  }, 300);

  onChange = (value: string) => {
    this.props.updateWidgetMetaProperty("stringValue", value);
    this.onChangeDebounced(value);
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.CODE_WIDGET;
  }
}

export default CodeWidget;
export const ConnectedCodeWidget = withMeta(CodeWidget);
