import { css } from "styled-components";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import { inputStyleFromStyleBlock, styleFromTextStyleBlock } from "./utils";

export const generateEditorStyles = (theme: GeneratedTheme) => {
  return css`
    .${CLASS_NAMES.CODE_EDITOR} {
      ${inputStyleFromStyleBlock(theme.inputs.input)}

      .cm-s-neo.CodeMirror {
        background-color: ${theme.inputs.input.backgroundColor.default};
        color: ${theme.colors.editor.text};
        ${styleFromTextStyleBlock(theme.typographies.code)}
      }
      .cm-s-neo .cm-comment {
        color: ${theme.colors.editor.comment};
      }
      .cm-s-neo .cm-property {
        color: ${theme.colors.editor.property};
      }
      .cm-s-neo .cm-atom,
      .cm-s-neo .cm-number {
        color: ${theme.colors.editor.number};
      }
      .cm-s-neo .cm-node,
      .cm-s-neo .cm-tag {
        color: ${theme.colors.editor.tag};
      }
      .cm-s-neo .cm-string {
        color: ${theme.colors.editor.string};
      }
      .cm-s-neo .cm-variable,
      .cm-s-neo .cm-qualifier {
        color: ${theme.colors.editor.variable};
      }
      .cm-s-neo .cm-keyword {
        color: ${theme.colors.editor.keyword};
        font-weight: bold;
      }

      .cm-s-neo .cm-builtin {
        color: ${theme.colors.editor.builtin};
      }

      /* Editor styling */
      .cm-s-neo .CodeMirror-linenumber {
        padding: 0;
        color: ${theme.colors.neutral500};
      }

      .cm-s-neo .CodeMirror-guttermarker {
        color: ${theme.colors.primary700};
      }
      .cm-s-neo .CodeMirror-guttermarker-subtle {
        color: ${theme.colors.neutral200};
      }

      .cm-s-neo .CodeMirror-cursor {
        background-color: ${theme.colors.primary700};
      }

      .CodeMirror-selected {
        background-color: ${theme.colors.neutral100};
      }

      && {
        .CodeMirror,
        .CodeMirror-gutters {
          background-color: ${theme.colors.neutral};
        }

        .CodeMirror .CodeMirror-guttermarker-subtle {
          background-color: ${theme.colors.neutral200};
        }
      }
      &.${CLASS_NAMES.DISABLED_MODIFIER} {
        background-color: ${theme.colors.neutral50};
        .cm-s-neo.CodeMirror {
          background-color: ${theme.colors.neutral50};
        }
        && {
          .CodeMirror,
          .CodeMirror-gutters {
            background-color: ${theme.colors.neutral50};
          }
        }
      }
    }
  `.join("");
};
