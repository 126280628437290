import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import { iconStyleFromStyleBlock } from "./utils";

export const generateIconStyles = (iconStyles: GeneratedTheme["icon"]) => {
  return `
        .${CLASS_NAMES.ICON} {
            ${iconStyleFromStyleBlock(iconStyles.default)}
        }   
    `;
};
