import {
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { select } from "redux-saga/effects";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { WidgetAddChild } from "legacy/actions/pageActions";
import { selectWidgets } from "legacy/actions/widgetActions";
import {
  EventType,
  MultiStepDef,
  TriggerStepType,
} from "legacy/constants/ActionConstants";
import {
  PropertyPaneConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { WidgetTypes, ModalSize } from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  VALIDATION_TYPES,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { APP_MODE } from "legacy/reducers/types";
import { updateModalMainCanvasHeightWithHeightPreset } from "legacy/sagas/WidgetOperationsSagasUtils";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  EMPTY_RADIUS,
} from "legacy/themes/constants";
import { getWidgetChildren } from "legacy/utils/WidgetPropsUtils";
import { generateClassName } from "legacy/utils/generators";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { Flag } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { ButtonWidgetProps } from "../ButtonWidget/types";
import WidgetFactory, {
  WidgetActionHook,
  WidgetActionResponse,
  CanvasWidgetsReduxState,
} from "../Factory";
import { paddingProperty } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { WidgetLayoutProps } from "../shared";
import { styleProperties } from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import ModalComponent from "./ModalComponent";
import { ModalContentContainer } from "./ModalContentContainer";

export default class ModalWidget extends BaseWidget<
  ModalWidgetProps,
  WidgetState
> {
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "Sections",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "sections",
            label: "Sections",
            headerControlType: "ADD_SECTION",
            controlType: "CHILD_LIST",
            useIndexedNames: false,
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          ...styleProperties({
            backgroundColorThemeValue: "{{ theme.colors.neutral }}",
            defaultBorderProperty: DEFAULT_CONTAINER_BORDER_OBJECT,
            borderThemeValue: DEFAULT_CONTAINER_BORDER_OBJECT,
            borderRadiusThemeValue: ({ theme }: { theme: any }) => {
              return {
                treatAsNull: false,
                value: createPerCornerBorderRadius(theme.borderRadius),
              };
            },
            defaultBorderRadiusProperty: EMPTY_RADIUS,
          }),
          {
            propertyName: "canOutsideClickClose",
            label: "Quick dismiss",
            helpText:
              "Allows dismissing the modal when a user presses the escape key or clicks the backdrop",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "hasBackdrop",
            label: "Backdrop",
            helpText: "Enables a backdrop behind the modal",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "widthPreset",
            label: "Width",
            helpText: "The modal width",
            controlType: "DROP_DOWN",
            defaultValue: ModalSize.SMALL,
            options: [
              {
                label: "Extra small",
                value: ModalSize.EXTRA_SMALL,
              },
              {
                label: "Small",
                value: ModalSize.SMALL,
              },
              {
                label: "Medium",
                value: ModalSize.MEDIUM,
              },
              {
                label: "Large",
                value: ModalSize.LARGE,
              },
              {
                label: "Fullscreen",
                value: ModalSize.FULLSCREEN,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          paddingProperty({}),
        ],
      },
      {
        sectionName: "Routes",
        sectionCategory: PropsPanelCategory.Routing,
        hidden(props, path, flags) {
          return !flags[Flag.MULTIPAGE_SLIDEOUTS];
        },
        children: [
          {
            propertyName: "routes",
            label: "Routes",
            headerControlType: "ADD_ROUTE",
            controlType: "ROUTE_LIST",
            helpText: "The URL routes that will be mapped to this modal",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onOpen",
            "Triggers an action when the modal is opened",
          ),
          getPopoverConfig(
            "onClose",
            "Triggers an action when the modal is closed",
          ),
        ],
      },
    ];
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isOpen: false,
      isVisible: false,
    };
  }

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<
      DataTree | WidgetAddChild | UpdateWidgetPropertiesPayload
    >;
  }): Generator<any, any, any> {
    const { widgetId, widgets, action } = params;
    if (widgets[widgetId].type !== WidgetTypes.MODAL_WIDGET) {
      return;
    }

    const updateList: WidgetActionResponse = [];
    const widget = widgets[widgetId] as Readonly<ModalWidgetProps>;
    const children = getWidgetChildren(widget.widgetId, widgets);

    switch (action.type) {
      case ReduxActionTypes.WIDGET_CREATE: {
        const payload = (action as ReduxAction<WidgetAddChild>).payload;
        if (payload.newWidgetId !== widgetId) {
          break;
        }

        for (const child of children) {
          const copy = fastClone(child);
          const closeAction = {
            direction: "close",
            id: widget.widgetId,
            type: TriggerStepType.CONTROL_MODAL,
            name: widget.widgetName,
          };
          if ((child as ButtonWidgetProps).buttonStyle === "SECONDARY_BUTTON") {
            (copy as ButtonWidgetProps).onClick = [closeAction];
            (copy as ButtonWidgetProps).dynamicTriggerPathList = [
              { key: "onClick" },
            ];
            updateList.push({ widgetId: child.widgetId, widget: copy });
          }
        }

        break;
      }
      case updateWidgetProperties.type: {
        if (
          !updateWidgetProperties.match(action) ||
          action.payload.widgetId !== widgetId
        ) {
          break;
        }
        const payload = action.payload;

        // Update the only child canvas of the first section of this modal
        // if we've changed the height preset
        // Note: this will be removed once we switch to sections which
        // removes the height preset from modals

        if (payload.updates.heightPreset !== undefined) {
          const theme = yield select(selectGeneratedTheme);

          updateModalMainCanvasHeightWithHeightPreset(
            widgets,
            widget.widgetId,
            payload.updates.heightPreset as ModalSize,
            theme,
          );
        }
        break;
      }
      default:
        break;
    }
    return updateList;
  };

  renderChildWidget = (childWidgetData: WidgetLayoutProps): ReactNode => {
    return WidgetFactory.createWidget(
      // add `isInModal` and `canExtend` properties here, to make sure existing app modals don't break
      { ...childWidgetData, isInModal: true, canExtend: true },
      this.props.appMode,
    );
  };

  closeModal = (e: any) => {
    this.props.updateWidgetMetaProperty("isClosing", true);
    this.props.updateWidgetMetaProperty("isOpen", false);
    e.stopPropagation();
    e.preventDefault();
  };

  finishClose = () => {
    this.props.updateWidgetMetaProperty("isVisible", false);
    this.props.updateWidgetMetaProperty("isClosing", false);
    this.props.hidePropertyPane();

    if (this.props.onClose) {
      super.runEventHandlers({
        steps: this.props.onClose,
        type: EventType.ON_MODAL_CLOSE,
        additionalNamedArguments: {},
      });
    }
  };

  onOpen = () => {
    this.props.updateWidgetMetaProperty("isOpen", true);
    if (this.props.onOpen) {
      super.runEventHandlers({
        steps: this.props.onOpen,
        type: EventType.ON_MODAL_OPEN,
        additionalNamedArguments: {},
      });
    }
  };

  getChildren(): ReactNode {
    if (this.props.children && this.props.children.length > 0) {
      const children = this.props.children.filter(Boolean);
      return children.length > 0 && children.map(this.renderChildWidget);
    }
  }

  makeModalSectionsContainer(content: ReactNode): ReactNode {
    return (
      <ModalContentContainer widgetId={this.props.widgetId}>
        {content}
      </ModalContentContainer>
    );
  }

  stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  stopMouseoverPropagation(children: ReactNode) {
    return (
      <div style={{ display: "contents" }} onMouseOver={this.stopPropagation}>
        {children}
      </div>
    );
  }

  makeModalComponent(content: ReactNode) {
    const sections = this.props.children as WidgetPropsRuntime[];

    return (
      <div onClick={this.stopPropagation}>
        <ModalComponent
          appMode={this.props.appMode}
          widgetId={this.props.widgetId}
          innerWidgets={sections}
          isOpen={!!this.props.isOpen}
          isClosing={!!this.props.isClosing}
          isVisible={!!this.props.isVisible}
          onClose={this.closeModal}
          afterVisibleChange={(visible) => {
            visible ? this.onOpen() : this.finishClose();
          }}
          className={generateClassName(this.props.widgetId)}
          hasBackdrop={!!this.props.hasBackdrop}
          canOutsideClickClose={!!this.props.canOutsideClickClose}
          widthPreset={this.props.widthPreset as ModalSize}
          heightPreset={this.props.heightPreset as ModalSize}
          styleProps={{
            padding: this.props.padding,
            border: this.props.border,
            borderRadius: this.props.borderRadius,
            backgroundColor: this.props.backgroundColor,
            fallbackBorderColorThemeKey: "neutral100",
            fallbackBackgroundColorThemeKey: "neutral",
          }}
          internalWidth={this.props.internalWidth}
        >
          {content}
        </ModalComponent>
      </div>
    );
  }

  getCanvasView() {
    let children = this.getChildren();
    children = this.makeModalSectionsContainer(children);
    children = this.showWidgetName(children, true);
    children = this.stopMouseoverPropagation(children);
    return this.makeModalComponent(children);
  }

  getPageView() {
    let children = this.getChildren();
    children = this.makeModalSectionsContainer(children);
    return this.makeModalComponent(children);
  }

  getWidgetType() {
    return WidgetTypes.MODAL_WIDGET;
  }
}

ModalWidget.defaultProps = {
  ...BaseWidget.defaultProps,
  canExtend: false,
};

export interface ModalWidgetProps extends WidgetPropsRuntime, WithMeta {
  appMode: APP_MODE;
  isOpen?: boolean;
  isClosing?: boolean;
  children?: WidgetPropsRuntime[];
  canOutsideClickClose?: boolean;
  hidePropertyPane: (widgetId?: string) => void;
  widthPreset?: string;
  heightPreset?: string;
  hasBackdrop: boolean;
  onOpen?: MultiStepDef;
  onClose?: MultiStepDef;
  // style properties
  backgroundColor: string;
  border: PerSideBorder;
  borderRadius: PerCornerBorderRadius;
  padding?: Padding;
}

const mapDispatchToProps = (dispatch: any) => ({
  hidePropertyPane: () => {
    dispatch(selectWidgets([]));
  },
});

export const ConnectedModalWidget = connect(
  null,
  mapDispatchToProps,
)(withMeta(ModalWidget));
