import {
  ApplicationScope,
  Dimension,
  EmailRegexString,
} from "@superblocksteam/shared";
import {
  isString,
  isUndefined,
  xor,
  without,
  isEmpty,
  set,
  isArray,
  isNumber,
  isFinite,
  noop,
  debounce,
  isEqual,
  isNull,
} from "lodash";
import React, { ComponentType, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { put, select, call } from "redux-saga/effects";
import {
  setSingleWidget,
  updateWidgetProperties,
  UpdateWidgetPropertiesPayload,
} from "legacy/actions/controlActions";
import {
  resetWidgetMetaProperty,
  setMetaProp,
} from "legacy/actions/metaActions";
import { WidgetAddChild } from "legacy/actions/pageActions";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  WidgetType,
  WidgetTypes,
  WIDGET_PADDING,
  GridDefaults,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  BASE_WIDGET_VALIDATION,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getDataTreeWidgetsById } from "legacy/selectors/dataTreeSelectors";
import {
  getIsWidgetSelected,
  getWidgetMetaProps,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import {
  mergeUpdatesWithBindingsOrTriggers,
  deleteWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { retryPromise } from "legacy/utils/Utils";

import {
  createRunEventHandlersPayload,
  createRunEventHandlersPayloadOptional,
} from "legacy/utils/actions";
import { Flags, FlagType, selectFlagById } from "store/slices/featureFlags";
import { Flag } from "store/slices/featureFlags";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { fastClone } from "utils/clone";
import { getDottedPathTo } from "utils/dottedPaths";
import log from "utils/logger";
import omit from "utils/omit";
import { getComponentDimensions } from "utils/size";
import { collapseTableData } from "utils/table";
import { sendInfoUINotification } from "../../../utils/notification";
import { getResponsiveCanvasScaleFactor } from "../../selectors/applicationSelectors";
import BaseWidget, { WidgetState } from "../BaseWidget";
import derivedInputFunctions from "../InputWidget/derived";
import { ButtonStyle } from "../Shared/Button";
import { CheckboxInput } from "../Shared/CheckboxInput";
import { getApplicableMaxHeight, getApplicableMinHeight } from "../base/sizing";
import withMeta from "../withMeta";
import { ReactTableComponentProps } from "./TableComponent";
import {
  ColumnProperties,
  ReactTableColumnProps,
  ColumnTypes,
  DEFAULT_TABLE_COLUMN_WIDTH,
  TABLE_COLUMN_MIN_WIDTH,
  TABLE_ROW_LEFT_PADDING,
  TABLE_ROW_RIGHT_PADDING,
  InsertRowOptions,
  PARSED_INPUT_TYPES,
  SingleCellProperties,
} from "./TableComponent/Constants";
import { getAllTableColumnKeys } from "./TableComponent/TableHelpers";
import {
  getDefaultColumnProperties,
  getTableStyles,
  renderCell,
  EditableCell,
  renderDropdown,
  renderActions,
  reorderColumns,
  renderLink,
  getFluidColumnWidth,
  getColumnWidth,
  getMergedColumnOrder,
  getIsColumnFrozen,
  sanitizeCellValue,
  TABLE_MULTISELECT_COLID,
  getVisibleOrderedColumnIds,
  columnHasNonDefaultProps,
  getPropertyValue,
  getCellProperties,
} from "./TableComponent/TableUtilities";
import {
  CompactModeTypes,
  PaginationTypes,
  EditInputType,
} from "./TableComponent/types";
import { ColumnFilter, TableFilters } from "./TableFiltersConstants";
import { TableManager, TableManagerContext } from "./TableManager";
import tablePropertyPaneConfig from "./TablePropertyPaneConfig";
import {
  TableWidgetProps,
  TableWidgetEvaluatedProps,
} from "./TableWidgetConstants";
import { insertDefaultTableData } from "./defaultDataProcessor";
import derivedProperties from "./parseDerivedProperties";
import type { WidgetProps } from "..";
import type { WidgetActionHook, CanvasWidgetsReduxState } from "../Factory";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import type {
  DataTree,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";
import type { AppState } from "store/types";

const ReactTableComponent = lazy<ComponentType<ReactTableComponentProps>>(() =>
  retryPromise(
    () =>
      import("./TableComponent").catch((e) => {
        log.error("Failed to lazy load TableComponent:", e);
        throw new Error(`TableComponent failed to load: ${e.message}`);
      }) as Promise<any>,
  ),
);

const getTableInnerDimensions = (props: TableWidgetEvaluatedProps) => {
  return {
    componentWidth:
      getComponentDimensions(props).componentWidth -
      2 * (WIDGET_PADDING + 1) - // container padding + border width
      TABLE_ROW_LEFT_PADDING -
      TABLE_ROW_RIGHT_PADDING,
    componentHeight: getComponentDimensions(props).componentHeight,
  };
};

type State = WidgetState & {
  isSaving: boolean;
};

export class TableWidget extends BaseWidget<TableWidgetEvaluatedProps, State> {
  customEditValidation: Record<string, undefined | string> = {};
  customEditMessage: Record<string, undefined | string> = {};
  editOptions: Record<
    string,
    undefined | string | Array<string | { label: string; value: string }>
  > = {};
  tableElement!: null | HTMLElement;
  tableScroller?: null | HTMLElement = undefined;
  dropdownValueToLabelMaps: Record<string, Record<string, string>> = {};
  totalRowsInserted = 0; // keeps track of all rows inserted, does not decrement when inserted row is removed
  suppressLoading = false;

  tableManager = new TableManager();

  state: State = {
    isSaving: false,
  };

  currentCellFocus: { row: number; columnId: string }[] = [];

  eventListenerController: AbortController | undefined;

  componentDidMount() {
    this.addEventListeners();
  }

  componentDidUpdate() {
    this.addEventListeners();
  }

  addEventListeners() {
    if (!this.tableElement) {
      this.tableElement = document.querySelector(
        `[data-superblocks=table${this.props.widgetId}]`,
      );

      this.eventListenerController = new AbortController();
    }
  }

  componentWillUnmount() {
    this.debouncedEditChange.flush();
    this.eventListenerController?.abort();
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      tableData: VALIDATION_TYPES.TABLE_DATA,
      tableHeader: VALIDATION_TYPES.TEXT,
      nextPageKey: VALIDATION_TYPES.TEXT,
      prevPageKey: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      defaultSort: VALIDATION_TYPES.TABLE_SORT,
      searchText: VALIDATION_TYPES.TEXT,
      currentEditDropdownSearchText: VALIDATION_TYPES.TEXT,
      defaultSearchText: VALIDATION_TYPES.TEXT,
      defaultFilters: VALIDATION_TYPES.FILTERS_DATA,
      defaultSelectedRow: VALIDATION_TYPES.DEFAULT_SELECTED_ROW,
      pageSize: VALIDATION_TYPES.NUMBER,
      "searchProps.border": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      "searchProps.borderRadius": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      backgroundColor: VALIDATION_TYPES.TEXT,
      selectedRowBackgroundColor: VALIDATION_TYPES.TEXT,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return tablePropertyPaneConfig;
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedRowIndex: undefined,
      selectedRowIndices: undefined,
      pageNo: 1,
      sortedColumn: undefined,
      searchText: undefined,
      filters: undefined,
      hiddenColumns: undefined,
      columnFreezes: undefined,
      tagsColorAssignment: undefined,
      editOverrides: undefined,
      currentEditFocus: undefined,
      currentEditDropdownSearchText: undefined,
      currentEditValue: undefined,
      deletedRowIndices: undefined,
      inserts: undefined,
    };
  }

  static getDerivedPropertiesMap() {
    return {
      tableDataWithInserts: `{{(()=>{${derivedProperties.getTableDataWithInserts}})()[0]}}`,
      tableDataWithInsertsOrderMap: `{{(()=>{${derivedProperties.getTableDataWithInserts}})()[1]}}`,
      selectedRow: `{{(()=>{${derivedProperties.getSelectedRow}})()}}`,
      selectedRowSchema: `{{(()=>{${derivedProperties.getSelectedRowSchema}})()}}`,
      selectedRows: `{{(()=>{${derivedProperties.getSelectedRows}})()}}`,
      pageSize: `{{(()=>{${derivedProperties.getPageSize}})()}}`,
      triggerRowSelection: "{{!!this.onRowSelected}}",
      filteredTableData: `{{(()=>{ ${derivedProperties.getFilteredTableData}})()[0]}}`,
      filteredOrderMap: `{{(()=>{ ${derivedProperties.getFilteredTableData}})()[1]}}`, // filteredTable -> table index map
      editedRows: `{{(()=>{${derivedProperties.getEditedRows}})()}}`,
      allEdits: `{{(()=>{${derivedProperties.getAllEdits}})()}}`,
      validationErrors: `{{(()=>{${derivedProperties.getAllValidationErrors}})()}}`,
      editedRowIndices: `{{(()=>{${derivedProperties.getEditedRowIndices}})()}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      sortedColumn: "defaultSort",
      searchText: "defaultSearchText",
      filters: "defaultFilters",
      selectedRowIndex: "defaultSelectedRow",
      selectedRowIndices: "defaultSelectedRow",
    };
  }

  getDisplayedValueForEditCell = ({
    columnId,
    rawValue,
    displayedValue,
    useLabelAsDisplayValue,
  }: {
    columnId: string;
    rawValue: string;
    displayedValue?: string;
    useLabelAsDisplayValue?: boolean;
  }) => {
    if (useLabelAsDisplayValue === false) {
      return displayedValue;
    }
    const dropdownValueToLabelMap = this.dropdownValueToLabelMaps?.[columnId];
    const stringifiedRawValue = JSON.stringify(rawValue);
    if (
      dropdownValueToLabelMap &&
      dropdownValueToLabelMap[stringifiedRawValue] !== undefined
    ) {
      return dropdownValueToLabelMap[stringifiedRawValue];
    }

    if (
      rawValue != null &&
      Array.isArray(rawValue) &&
      dropdownValueToLabelMap
    ) {
      return rawValue
        .map((value) => {
          const stringifiedValue = JSON.stringify(value);
          if (dropdownValueToLabelMap[stringifiedValue] !== undefined) {
            return dropdownValueToLabelMap[stringifiedValue];
          }
          return stringifiedValue;
        })
        .join(", ");
    }

    return displayedValue;
  };

  previousColumnsString = "";
  previousColumns: ReactTableColumnProps[] = [];

  getReactTableColumns(): ReactTableColumnProps[] {
    const columns: ReactTableColumnProps[] = [];
    const {
      sortedColumn,
      columnOrder,
      columnSizeMap,
      primaryColumns = {},
      columnFreezes,
    } = this.props;

    const tableProps = this.props;

    let allColumns = Object.assign(
      {},
      TableWidget.createTablePrimaryColumns(
        this.props as any,
        this.props.tableData,
        this.props.inserts,
      ) || primaryColumns,
    );

    const sortColumn = sortedColumn?.column;
    const sortOrder = sortedColumn?.asc;
    if (columnOrder) {
      allColumns = reorderColumns(allColumns, columnOrder, columnFreezes);
    }

    const allColumnProperties = Object.values(allColumns).filter(
      (col) => col.isVisible,
    );
    const { componentWidth } = getTableInnerDimensions(this.props);

    const fluidColumnWidth = getFluidColumnWidth(
      componentWidth,
      allColumns,
      columnSizeMap,
    );

    const hasFrozenColumns =
      Object.values(columnFreezes ?? {}).some((val) => val) ||
      allColumnProperties.some((col) => col.isFrozen);
    if (this.props.multiRowSelection) {
      columns.push({
        Header: "",
        // Using a function instead of string because react-table treats a.b as "object member access"
        // https://github.com/tannerlinsley/react-table/issues/513
        accessor: (v: any) => v[TABLE_MULTISELECT_COLID],
        // Since we don't use a unique accessor any more, react-use will get mad
        id: TABLE_MULTISELECT_COLID,
        minWidth: 40,
        width: 40,
        draggable: false,
        isHidden: false,
        isDerived: false,
        sticky: hasFrozenColumns ? "left" : undefined,
        columnProperties: {
          id: TABLE_MULTISELECT_COLID,
          label: "",
          columnType: "$sb_multiselect",
          isVisible: true,
          enableFilter: false,
          enableSort: false,
          index: 0,
          width: 40,
          computedValue: "",
          isDerived: false,
          textWrap: false,
          tagDisplayConfig: {},
          headerIcon: "",
          headerIconPosition: "LEFT",
        },
        Cell: (props: any) => {
          return (
            <div className="table-multi-select-checkbox">
              <CheckboxInput
                isChecked={this.getComputedSelectedRowIndices().includes(
                  props.cell.row.index,
                )}
                isDisabled={false}
                isValid={true}
              />
            </div>
          );
        },
      });
    }

    for (let index = 0; index < allColumnProperties.length; index++) {
      const columnProperties = allColumnProperties[index];
      const isHidden = !columnProperties.isVisible;
      const isFrozen = getIsColumnFrozen(
        columnProperties.id,
        columnFreezes,
        columnProperties.isFrozen,
      );

      if (isHidden) {
        continue;
      }
      const accessor = columnProperties.id;
      // this is a bit of a hack, but we need the customEditValidation value to maintained in this context but
      // including it in the column will cause the table cells to mount/unmount when it changes from true<->false
      this.customEditValidation[columnProperties.id] =
        columnProperties.editCustomValidationRule;
      this.customEditMessage[columnProperties.id] =
        columnProperties.editCustomErrorMessage;
      this.editOptions[columnProperties.id] = columnProperties.editOptions;

      const columnNotation =
        columnProperties.notation !== undefined
          ? columnProperties.notation
          : "standard";
      const columnData: ReactTableColumnProps = {
        Header:
          typeof columnProperties.label === "object" ||
          columnProperties.label == null
            ? ""
            : columnProperties.label,
        // Using a function instead of string because react-table treats a.b as "object member access"
        // https://github.com/tannerlinsley/react-table/issues/513
        accessor: (v: any) => v[accessor],
        // Since we don't use a unique accessor any more, react-use will get mad
        id: accessor,
        ...getColumnWidth(accessor, columnSizeMap, fluidColumnWidth),
        minWidth: TABLE_COLUMN_MIN_WIDTH,
        draggable: true,
        isHidden,
        isAscOrder: columnProperties.id === sortColumn ? sortOrder : undefined,
        isDerived: columnProperties.isDerived,
        sticky: isFrozen ? "left" : undefined,
        metaProperties: {
          isHidden,
          type: columnProperties.columnType,
        } satisfies ReactTableColumnProps["metaProperties"],
        columnProperties: {
          ...omit(
            columnProperties as ColumnProperties,
            "editCustomValidationRule",
            "editCustomErrorMessage",
            "editOptions",
          ),
          index: this.props.multiRowSelection
            ? columnProperties.index + 1
            : columnProperties.index,
          notation: columnNotation,
        },
        Cell: (props: any) => {
          const currentRowIndex: number = props.cell.row.index;
          const originalRowIndex =
            this.props.filteredOrderMap?.[currentRowIndex] ?? currentRowIndex;
          const isEdited =
            this.props.editOverrides?.[originalRowIndex] != null &&
            columnProperties.id in this.props.editOverrides[originalRowIndex];
          const isInserted = this.getIsRowInserted(originalRowIndex);
          const isDeleted = this.getIsRowDeleted(originalRowIndex);

          const indexInTableDataWithInserts =
            this.props.tableDataWithInsertsOrderMap?.[originalRowIndex] ??
            originalRowIndex;
          const cellProperties = getCellProperties(
            this.props?.cellProps?.textStyle,
            columnProperties,
            indexInTableDataWithInserts,
          );
          const onDropdownSearchTextChanged = (value: string) => {
            this.context.updateWidgetMetaProperty?.(
              this.props.widgetId,
              "currentEditDropdownSearchText",
              value,
            );
            if (columnProperties.onDropdownSearchTextChanged) {
              this.runEventHandlers?.({
                steps: columnProperties.onDropdownSearchTextChanged,
                type: EventType.ON_TEXT_CHANGE,
                additionalNamedArguments: {
                  currentEditDropdownSearchText: value,
                },
              });
            }
          };

          const isEditable =
            !isDeleted &&
            (isInserted
              ? columnProperties.isEditableOnInsertion
              : columnProperties.isEditable);

          if (isEditable) {
            let rawValue;
            if (isInserted) {
              rawValue =
                this.props.inserts?.insertedRowsById?.[originalRowIndex]?.[
                  columnProperties.id
                ];
            } else if (isEdited) {
              rawValue =
                this.props.editOverrides?.[originalRowIndex]?.[
                  columnProperties.id
                ].value;
            } else if (columnProperties.isDerived) {
              rawValue = props.cell.value;
            } else {
              rawValue =
                this.props.tableData?.[originalRowIndex]?.[columnProperties.id];
            }
            const value = isInserted ? (rawValue ?? "") : props.cell.value;
            return (
              <EditableCell
                value={value}
                rawValue={rawValue}
                columnType={columnProperties.columnType}
                isInserted={isInserted}
                columnName={columnProperties.label}
                cellProps={{
                  isHidden,
                  cellProperties,
                  tableWidth: componentWidth,
                  tagsColorAssignment: this.props.tagsColorAssignment,
                  tagsCustomColorAssignment: columnProperties.tagDisplayConfig,
                  notation: columnNotation,
                  currency: columnProperties.currency,
                  minimumFractionDigits: columnProperties.minimumFractionDigits,
                  maximumFractionDigits: columnProperties.maximumFractionDigits,
                  booleanStyleFalse: columnProperties.booleanStyleFalse,
                  compactMode: this.props.compactMode,
                  isFocused: props.isFocused,
                  displayedValue: this.getDisplayedValueForEditCell({
                    columnId: columnProperties.id,
                    rawValue,
                    displayedValue: cellProperties.displayedValue,
                    useLabelAsDisplayValue:
                      columnProperties.useLabelAsDisplayValue,
                  }),
                  cellIcon: getPropertyValue(
                    columnProperties.cellIcon,
                    indexInTableDataWithInserts,
                    true,
                  ),
                  cellIconPosition: columnProperties.cellIconPosition ?? "LEFT",
                  inputFormat: getPropertyValue(
                    columnProperties.inputFormat,
                    indexInTableDataWithInserts,
                    true,
                  ),
                  outputFormat: getPropertyValue(
                    columnProperties.outputFormat,
                    indexInTableDataWithInserts,
                    true,
                  ),
                  manageTimezone: columnProperties.manageTimezone,
                  timezone: getPropertyValue(
                    columnProperties.timezone,
                    indexInTableDataWithInserts,
                    true,
                  ),
                  displayTimezone: getPropertyValue(
                    columnProperties.displayTimezone,
                    indexInTableDataWithInserts,
                    true,
                  ),
                  isMultiselecting: this.currentCellFocus.length > 1,
                }}
                editProps={{
                  handleEditStart: this.handleEditStart.bind(
                    this,
                    originalRowIndex,
                    columnProperties.id,
                    this.getCellValue(
                      originalRowIndex,
                      columnProperties.id,
                    ) as unknown as string,
                  ),
                  handleEditChange: this.debouncedEditChange,
                  handleEditStop: this.onEditStop.bind(
                    this,
                    columnProperties,
                    currentRowIndex,
                  ),
                  onDropdownSearchTextChanged: onDropdownSearchTextChanged,
                  currentEditDropdownSearchText:
                    this.props.currentEditDropdownSearchText,
                  handleOneClickEdit: this.handleOneClickEdit.bind(
                    this,
                    columnProperties,
                    originalRowIndex,
                  ),
                  isEditFocused:
                    this.props.currentEditFocus?.row === originalRowIndex &&
                    this.props.currentEditFocus?.columnId ===
                      columnProperties.id,
                  isEdited,
                  currentEditValue: this.props.currentEditValue,
                  editInputType: columnProperties.editInputType,
                  editCustomValidationRule:
                    this.customEditValidation[columnProperties.id],
                  editCustomErrorMessage:
                    this.customEditMessage[columnProperties.id],
                  editMaxLength: columnProperties.editMaxLength,
                  editMinLength: columnProperties.editMinLength,
                  editIsRequired: isInserted
                    ? columnProperties.isRequiredOnInsertion
                    : columnProperties.editIsRequired,
                  editMultiSelect: columnProperties.editMultiSelect,
                  dropdownOptionsLoading: this.getDropdownOptionsLoading(),
                  editDropdownClientSideFiltering:
                    columnProperties.editDropdownClientSideFiltering,
                  editOptions: this.editOptions[columnProperties.id],
                  transformation: columnProperties.transformation,
                  validationErrors: isInserted
                    ? (this.props.inserts?.insertedRowValidations?.[
                        originalRowIndex
                      ]?.[columnProperties.id] ?? [])
                    : (this.props.editOverrides?.[originalRowIndex]?.[
                        columnProperties.id
                      ]?.validationErrors ?? []),
                  editMinDate: columnProperties.editMinDate,
                  editMaxDate: columnProperties.editMaxDate,
                  editTwentyFourHourTime:
                    columnProperties.editTwentyFourHourTime,
                }}
                tableName={this.props.widgetName}
                compactMode={this.props.compactMode ?? CompactModeTypes.DEFAULT}
                maxLinesPerRow={this.props.maxLinesPerRow}
                position={{
                  currentRowIndex,
                  columnId: columnProperties.id,
                  originalRowIndex,
                }}
              />
            );
          } else if (columnProperties.columnType === ColumnTypes.BUTTON) {
            const buttonProps = {
              rowIndex: currentRowIndex,
              isSelected: !!props.row.isSelected,
              isDisabled: cellProperties.isDisabled,
              onCommandClick: (action: MultiStepDef, onComplete: () => void) =>
                this.onCommandClick(currentRowIndex, action, onComplete),
              handleRowSelect: this.handleRowSelect,
              backgroundColor: cellProperties.buttonBackgroundColor,
              buttonVariant:
                (cellProperties.buttonVariant as ButtonStyle) ??
                "PRIMARY_BUTTON",
              buttonLabelColor: cellProperties.buttonLabelColor,
              cellIcon: getPropertyValue(
                columnProperties.cellIcon,
                indexInTableDataWithInserts,
                true,
              ),
              cellIconPosition: cellProperties.cellIconPosition ?? "LEFT",
              compactMode: this.props.compactMode,
              columnActions: [
                {
                  id: columnProperties.id,
                  label:
                    cellProperties.buttonLabel ||
                    (cellProperties.cellIcon ? "" : "Action"),
                  actions: columnProperties.onClick || [],
                },
              ],
            };
            return renderActions(
              buttonProps,
              isHidden,
              cellProperties,
              this.props.compactMode,
              {
                currentRowIndex,
                columnId: columnProperties.id,
                originalRowIndex,
              },
            );
          } else if (columnProperties.columnType === "dropdown") {
            let options = [];
            try {
              options = JSON.parse(columnProperties.dropdownOptions || "");
            } catch (e) {
              // Noop
            }
            return renderDropdown({
              options: options,
              onItemSelect: noop,
              onOptionChange: columnProperties.onOptionChange || [],
              selectedIndex: isFinite(props.cell.value)
                ? props.cell.value
                : undefined,
            });
          } else if (columnProperties.columnType === "link") {
            return renderLink({
              cellProperties,
              isHidden,
              compactMode: this.props.compactMode,
              position: {
                currentRowIndex,
                columnId: columnProperties.id,
                originalRowIndex,
              },
              maxLinesPerRow: this.props.maxLinesPerRow,
              tableCellTextStyle: this.props.cellProps?.textStyle,
            });
          } else {
            return renderCell({
              value: props.cell.value,
              columnType: columnProperties.columnType,
              editInputType: columnProperties.editInputType,
              maxLinesPerRow: this.props.maxLinesPerRow,
              tableCellTextStyle: this.props.cellProps?.textStyle,
              typographies: this.props.typographies,
              cellProps: {
                // since these are overridden by the cellProperties
                ...(columnProperties as Omit<
                  ColumnProperties,
                  keyof SingleCellProperties
                >),
                ...getCellProperties(
                  tableProps?.cellProps?.textStyle,
                  columnProperties,
                  indexInTableDataWithInserts,
                ),
                isHidden,
                cellProperties,
                tableWidth: componentWidth,
                tagsColorAssignment: this.props.tagsColorAssignment,
                tagsCustomColorAssignment: columnProperties.tagDisplayConfig,
                booleanStyleFalse: columnProperties.booleanStyleFalse,
                compactMode: this.props.compactMode,
                isFocused: props.isFocused,
                isMultiselecting: this.currentCellFocus.length > 1,
              },
              position: {
                currentRowIndex,
                columnId: columnProperties.id,
                originalRowIndex,
              },
            });
          }
        },
      };
      columns.push(columnData);
    }

    // This is a hack that replaces this previous hack:
    // https://github.com/superblocksteam/superblocks/blob/8b934b26af8ef7fd8c8fe6c092cdfc53f5c83f71/packages/ui/src/legacy/widgets/TableWidget/TableComponent/Table.tsx#L244
    // for memoizing columns. The correct solution is to properly memoize the columns and cell renderers to do proper equality checks, but
    // this is an incremental improvement for now.
    const columnString = JSON.stringify(columns);
    if (columnString === this.previousColumnsString) {
      return this.previousColumns;
    }
    this.previousColumnsString = columnString;
    this.previousColumns = columns;
    return columns;
  }

  static transformData = (
    tableData: Array<Record<string, unknown>>,
    filteredOrderMap: Array<number>,
    columns: ReactTableColumnProps[],
    editOverrides: Record<
      number,
      Record<string, { value: unknown; validationErrors?: Array<string> }>
    >,
  ) => {
    const updatedTableData = [];
    // For each row in the tableData (filteredTableData)
    for (let row = 0; row < tableData.length; row++) {
      const originalRowIndex = filteredOrderMap?.[row] ?? row;

      // Get the row object
      const data: { [key: string]: any } = tableData[row];
      if (data) {
        const tableRow: { [key: string]: any } = {};
        // For each column in the expected columns of the table
        for (let colIndex = 0; colIndex < columns.length; colIndex++) {
          // Get the column properties
          const column = columns[colIndex];
          let value = data[column.columnProperties.id];
          // if that row/column has been edited, update the tabledata to reflect this
          if (
            editOverrides?.[originalRowIndex] &&
            column.columnProperties.id in editOverrides[originalRowIndex]
          ) {
            value =
              editOverrides?.[originalRowIndex]?.[column.columnProperties.id]
                .value;
          }

          if (column.metaProperties) {
            const type = column.metaProperties.type;

            switch (type) {
              case ColumnTypes.TAGS: {
                tableRow[column.columnProperties.id] =
                  isString(value) || isNumber(value) ? [value] : value;
                break;
              }
              default: {
                const data =
                  isString(value) || isFinite(value)
                    ? value
                    : isUndefined(value) || isNull(value)
                      ? ""
                      : JSON.stringify(value);
                tableRow[column.columnProperties.id] = data;
                break;
              }
            }
          }
        }

        updatedTableData.push(tableRow);
      }
    }

    return updatedTableData;
  };

  static getParsedComputedValues(value: string | Array<unknown>) {
    let computedValues: Array<unknown> = [];
    if (isString(value)) {
      try {
        computedValues = JSON.parse(value);
      } catch (e) {
        log.debug("Error parsing column value: " + value);
      }
    } else if (Array.isArray(value)) {
      computedValues = value;
    } else {
      log.debug("Error parsing column values: " + value);
    }
    return computedValues;
  }

  getEmptyRow = () => {
    const columnKeys: string[] = getAllTableColumnKeys(this.props.tableData);
    const selectedRow: { [key: string]: any } = {};
    for (let i = 0; i < columnKeys.length; i++) {
      selectedRow[columnKeys[i]] = "";
    }
    return selectedRow;
  };

  getSelectedRow = (
    filteredTableData?: Array<Record<string, unknown>>,
    selectedRowIndex?: number,
  ) => {
    if (
      !filteredTableData ||
      selectedRowIndex === undefined ||
      selectedRowIndex === -1 ||
      selectedRowIndex === null
    ) {
      return this.getEmptyRow();
    }
    return {
      ...filteredTableData[selectedRowIndex],
    };
  };

  static getDerivedColumns(
    derivedColumns: Record<string, ColumnProperties>,
    tableColumnCount: number,
  ) {
    if (!derivedColumns) return [];
    //update index property of all columns in new derived columns
    return (
      Object.keys(derivedColumns)?.map((columnId: string, index: number) => {
        return {
          ...derivedColumns[columnId],
          index: index + tableColumnCount,
        };
      }) || []
    );
  }

  static createTablePrimaryColumns(
    props: TableWidgetProps,
    tableData: Readonly<TableWidgetEvaluatedProps["tableData"]>,
    inserts: Readonly<TableWidgetEvaluatedProps["inserts"]>,
  ): Record<string, ColumnProperties> | undefined {
    const {
      primaryColumns = {},
      columnNameMap = {},
      columnTypeMap = {},
      derivedColumns = {},
      migrated,
    } = props;

    const previousColumnIds = Object.keys(primaryColumns);
    const tableColumns: Record<string, ColumnProperties> = {};
    //Get table level styles
    const tableStyles = getTableStyles(props);
    const columnKeys: string[] = getAllTableColumnKeys(
      tableData,
      // if there is no table data, fallback to the previous column ids
      tableData.length === 0 ? previousColumnIds : undefined,
    );
    // Generate default column properties for all columns
    // But do not replace existing columns with the same id
    for (let index = 0; index < columnKeys.length; index++) {
      const i = columnKeys[index];
      const prevIndex = previousColumnIds.indexOf(i);
      if (prevIndex > -1) {
        // we found an existing property with the same column id use the previous properties
        tableColumns[i] = primaryColumns[i];
      } else if (props.cachedColumnSettings?.[i]) {
        tableColumns[i] = props.cachedColumnSettings[i];
      } else {
        const columnProperties = getDefaultColumnProperties(
          i,
          index,
          props.widgetName,
        );
        if (migrated === false) {
          if ((columnNameMap as Record<string, string>)[i]) {
            columnProperties.label = columnNameMap[i];
          }
          if (
            (
              columnTypeMap as Record<
                string,
                {
                  type: ColumnTypes;
                  inputFormat?: string;
                  format?: string;
                  timezone?: string;
                  displayTimezone?: string;
                }
              >
            )[i]
          ) {
            columnProperties.columnType = columnTypeMap[i].type;
            columnProperties.inputFormat = columnTypeMap[i].inputFormat;
            columnProperties.outputFormat = columnTypeMap[i].format;
            columnProperties.timezone = columnTypeMap[i].timezone;
            columnProperties.displayTimezone = columnTypeMap[i].displayTimezone;
          }
        }
        //add column properties along with table level styles
        tableColumns[columnProperties.id] = {
          ...columnProperties,
          ...tableStyles,
        };
      }
    }
    // Get derived columns
    const updatedDerivedColumns = TableWidget.getDerivedColumns(
      derivedColumns,
      Object.keys(tableColumns).length,
    );

    //add derived columns to primary columns
    updatedDerivedColumns.forEach((derivedColumn: ColumnProperties) => {
      tableColumns[derivedColumn.id] = derivedColumn;
    });

    const newColumnIds = Object.keys(tableColumns);
    if (xor(previousColumnIds, newColumnIds).length > 0) return tableColumns;
    else return;
  }

  static updateColumnProperties = function* (
    props: TableWidgetProps,
    tableColumns?: Record<string, ColumnProperties>,
    isDeepBindingsFeatureFlagEnabled?: FlagType, // TODO: remove after FF is on
  ): Generator<unknown, TableWidgetProps | undefined | void, unknown> {
    const { primaryColumns = {} } = props;
    const { columnOrder, migrated } = props;
    if (tableColumns) {
      const previousColumnIds = Object.keys(primaryColumns);
      const newColumnIds = Object.keys(tableColumns);

      if (xor(previousColumnIds, newColumnIds).length > 0) {
        const columnIdsToAdd = without(newColumnIds, ...previousColumnIds);

        const propertiesToAdd: Record<string, unknown> = {};
        columnIdsToAdd.forEach((id: string) => {
          Object.entries(tableColumns[id]).forEach(([key, value]) => {
            propertiesToAdd[
              `primaryColumns${getDottedPathTo(id)}${getDottedPathTo(key)}`
            ] = value;
          });
        });

        // If new columnOrders have different values from the original columnOrders
        if (xor(newColumnIds, columnOrder).length > 0) {
          const sortedIds = getMergedColumnOrder(
            columnOrder ?? [],
            newColumnIds,
          );
          propertiesToAdd["columnOrder"] = sortedIds;
        }

        const pathsToDelete: string[] = [];
        if (migrated === false) {
          propertiesToAdd["migrated"] = true;
        }
        let updatedWidget = fastClone(props);
        const propertyUpdates = (yield call(
          mergeUpdatesWithBindingsOrTriggers,
          props,
          tablePropertyPaneConfig,
          propertiesToAdd,
          isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
        )) as unknown as Record<string, unknown>;
        // We loop over all updates
        Object.entries(propertyUpdates).forEach(
          ([propertyPath, propertyValue]) => {
            // since property paths could be nested, we use lodash set method
            set(updatedWidget, propertyPath, propertyValue);
          },
        );
        const columnsIdsToDelete = without(previousColumnIds, ...newColumnIds);
        columnsIdsToDelete.forEach((id: string) => {
          pathsToDelete.push(`primaryColumns${getDottedPathTo(id)}`);
        });
        if (pathsToDelete.length) {
          updatedWidget = (yield call(
            deleteWithBindingsOrTriggers,
            updatedWidget,
            pathsToDelete,
          )) as unknown as TableWidgetProps;
        }
        // add any delete column properties to the cachedColumnSettings
        columnsIdsToDelete.forEach((id: string) => {
          if (
            columnHasNonDefaultProps(props.primaryColumns[id], props.widgetName)
          ) {
            if (!updatedWidget.cachedColumnSettings) {
              updatedWidget.cachedColumnSettings = {};
            }
            updatedWidget.cachedColumnSettings[id] = props.primaryColumns[id];
          }
        });
        // delete any column properties from cachedColumnSettings that are in newColumnIds
        newColumnIds.forEach((id: string) => {
          delete updatedWidget?.cachedColumnSettings?.[id];
        });

        yield put(setSingleWidget(props.widgetId, updatedWidget, false, false));
        return updatedWidget;
      }
    }
  };

  static initializeTable = function* (
    widgetId: string,
    props: TableWidgetProps,
    evaluatedWidget: Readonly<TableWidgetEvaluatedProps>,
    isDeepBindingsFeatureFlagEnabled?: FlagType, // TODO: remove after FF is on
  ): Generator<unknown, TableWidgetProps, unknown> {
    const { tableData } = evaluatedWidget;
    let newPrimaryColumns;
    // When we have tableData, the primaryColumns order is unlikely to change
    // When we don't have tableData primaryColumns will not be available, so let's let it be.

    if (tableData.length > 0) {
      newPrimaryColumns = TableWidget.createTablePrimaryColumns(
        props,
        evaluatedWidget.tableData,
        evaluatedWidget.inserts,
      );
    }
    if (!newPrimaryColumns) {
      return props;
    } else {
      const widget = yield* TableWidget.updateColumnProperties(
        props,
        newPrimaryColumns,
        isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
      );
      return widget || props;
    }
  };

  static updateExisting = function* (
    widgetId: string,
    props: TableWidgetProps,
    evaluatedWidget: Readonly<TableWidgetEvaluatedProps>,
    isDeepBindingsFeatureFlagEnabled?: FlagType, // TODO: remove after FF is on
  ): Generator<any, any, any> {
    const evaluatedWidgets: Record<string, DataTreeWidget> = yield select(
      getDataTreeWidgetsById,
    );
    const previousWidget = evaluatedWidgets[widgetId] as unknown as
      | TableWidgetEvaluatedProps
      | undefined;
    let updatedWidget = fastClone(props);
    if (!previousWidget) {
      // Make sure that widget.primaryColumns is set
      updatedWidget = yield* TableWidget.initializeTable(
        widgetId,
        props,
        evaluatedWidget,
        isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
      );
    }

    const { primaryColumns = {} } = updatedWidget;

    // Check if data is modifed by comparing the stringified versions of the previous and next tableData
    // But don't remove columns when the table becomes empty
    const tableDataModified =
      previousWidget &&
      evaluatedWidget.tableData.length &&
      JSON.stringify(evaluatedWidget.tableData) !==
        JSON.stringify(previousWidget?.tableData);

    // If the user has changed the tableData OR
    // The binding has returned a new value,
    // but not if this is the first time rendering the table
    if (tableDataModified) {
      // Get columns keys from this.props.tableData
      const columnIds: string[] = getAllTableColumnKeys(
        evaluatedWidget.tableData,
      );
      // Get column keys from columns except for derivedColumns
      const primaryColumnIds = Object.keys(primaryColumns).filter(
        (id: string) => {
          return !primaryColumns[id].isDerived; // Filter out the derived columns
        },
      );
      // If the keys which exist in the tableData are different from the ones available in primaryColumns
      if (xor(columnIds, primaryColumnIds).length > 0) {
        const newTableColumns = TableWidget.createTablePrimaryColumns(
          updatedWidget,
          evaluatedWidget.tableData,
          evaluatedWidget.inserts,
        );
        // This updates the widget
        if (!newTableColumns) {
          // Don't modify the filteredTableData if primaryColumns isn't changing
          return;
        }
        yield* TableWidget.updateColumnProperties(
          updatedWidget,
          newTableColumns,
          isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
        );
      }

      // Since the table config has changed, we need to reinitialize the selectedRow, filters, etc.
      // For server side pagnination, we need to keep the pageNumber
      if (props.pageType === PaginationTypes.SERVER_SIDE) {
        const allMetaProps = Object.keys(TableWidget.getMetaPropertiesMap());
        yield put(
          resetWidgetMetaProperty(widgetId, {
            propertyNames: allMetaProps.filter((prop) => prop !== "pageNo"),
          }),
        );
      } else {
        yield put(resetWidgetMetaProperty(widgetId));
      }
    }
  };

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<
      DataTree | WidgetAddChild | UpdateWidgetPropertiesPayload
    >;
    flags: Flags;
  }) {
    const { widgetId, widgets, action } = params;
    if (widgets[widgetId].type !== WidgetTypes.TABLE_WIDGET) {
      return;
    }
    switch (action.type) {
      case updateWidgetProperties.type: {
        if (
          !updateWidgetProperties.match(action) ||
          action.payload.widgetId !== widgetId
        ) {
          return;
        }
        const updatePayload = action.payload;
        const updates = updatePayload.updates;
        if (
          Object.keys(updates).some((key) =>
            key.startsWith("primaryColumns"),
          ) ||
          updates.columnOrder
        ) {
          //any column related changes should clear the column related meta
          yield put(setMetaProp(widgetId, "hiddenColumns", undefined));
        }
        if (
          Object.keys(updates).some(
            (key) =>
              key.startsWith("primaryColumns") && key.endsWith("isFrozen"),
          )
        ) {
          const changedColumnFreezes = Object.entries(updates).reduce(
            (accum: Record<string, boolean>, [key, value]) => {
              if (
                key.startsWith("primaryColumns") &&
                key.endsWith("isFrozen")
              ) {
                const columnName = key.split(".")[1];
                accum[columnName] = value as unknown as boolean;
              }
              return accum;
            },
            {},
          );
          const metaProps: WidgetMetadata = yield select(
            getWidgetMetaProps,
            widgetId,
          );
          yield put(
            setMetaProp(widgetId, "columnFreezes", {
              ...((metaProps?.columnFreezes as Record<string, boolean>) ?? {}),
              ...changedColumnFreezes,
            }),
          );
        }
        break;
      }
      case ReduxActionTypes.TREE_WILL_UPDATE: {
        const evaluatedWidget: TableWidgetEvaluatedProps = (
          action.payload as DataTree
        ).PAGE[widgets[widgetId].widgetName] as any;
        if (!evaluatedWidget || evaluatedWidget.isLoading) {
          break;
        }

        yield* TableWidget.updateExisting(
          widgetId,
          widgets[widgetId] as TableWidgetProps,
          evaluatedWidget,
          params.flags[Flag.ENABLE_DEEP_BINDINGS_PATHS], // To be removed
        );
        break;
      }
      case ReduxActionTypes.WIDGET_CREATE: {
        if ((action.payload as WidgetAddChild).newWidgetId === widgetId) {
          return insertDefaultTableData(widgetId, widgets);
        }
        break;
      }
    }
    return;
  };

  updateDefaultSelectedRow = (
    filteredTableData: Array<Record<string, unknown>> | undefined,
  ) => {
    const selectedRowIndex =
      isNumber(this.props.defaultSelectedRow) &&
      isFinite(this.props.defaultSelectedRow)
        ? this.props.defaultSelectedRow
        : -1;
    if (this.props.selectedRowIndex === selectedRowIndex) {
      return;
    }
    this.context.updateWidgetMetaProperty?.(
      this.props.widgetId,
      "selectedRowIndex",
      selectedRowIndex,
    );
    // Update the default selected row value if the table has data
    if (!isEmpty(filteredTableData) && selectedRowIndex !== -1) {
      this.context.updateWidgetMetaProperty?.(
        this.props.widgetId,
        "selectedRow",
        filteredTableData?.[selectedRowIndex],
      );
      if (this.props.onRowSelected) {
        this.runEventHandlers?.({
          steps: this.props.onRowSelected,
          type: EventType.ON_CELL_SELECTED,
        });
      }
    }
  };

  getSelectedRowIndexes = (selectedRowIndices: string) => {
    return selectedRowIndices
      ? selectedRowIndices.split(",").map((i) => Number(i))
      : [];
  };

  getComputedSelectedRowIndices = (): number[] => {
    const { multiRowSelection, selectedRowIndices } = this.props;
    const computedSelectedRows = multiRowSelection
      ? Array.isArray(selectedRowIndices)
        ? selectedRowIndices
        : [selectedRowIndices]
      : [];
    return computedSelectedRows.filter(
      (index) =>
        typeof index === "number" &&
        index >= 0 &&
        index < (this.props.filteredTableData ?? []).length,
    );
  };

  getOriginalRowIndex = (index?: number) => {
    if (index == null) {
      return 0;
    }
    return this.props.filteredOrderMap?.[index] ?? index;
  };

  getCurrentRowIndex = (originalIndex: number) => {
    return this.props.filteredOrderMap?.indexOf(originalIndex) ?? originalIndex;
  };

  getIsRowEdited = (rowIndex: number, isOriginalIndex = true) => {
    const originalRowIndex = isOriginalIndex
      ? rowIndex
      : this.getOriginalRowIndex(rowIndex);
    return (
      this.props.editOverrides?.[originalRowIndex] != null &&
      Object.keys(this.props.editOverrides?.[originalRowIndex]).length > 0
    );
  };

  getIsRowInserted = (rowIndex: number, isOriginalIndex = true) => {
    const originalRowIndex = isOriginalIndex
      ? rowIndex
      : this.getOriginalRowIndex(rowIndex);
    return this.props.inserts?.insertedRowsById?.[originalRowIndex] != null;
  };

  getCellBackground = (currentRowIndex: number, columnId: string) => {
    const originalRowIndex = this.getOriginalRowIndex(currentRowIndex);
    if (!this.props.primaryColumns?.[columnId]?.cellBackground) {
      return;
    }
    return getPropertyValue(
      this.props.primaryColumns[columnId].cellBackground,
      originalRowIndex,
    );
  };

  getIsLoading = () => {
    if (this.suppressLoading) {
      return false;
    }
    // If a dropdown cell (with server side filtering) is being edited,
    // skip the loading animation.
    if (this.props.currentEditFocus) {
      const { columnId } = this.props.currentEditFocus;
      const clientSideFiltering =
        this.props.primaryColumns[columnId]?.editDropdownClientSideFiltering ??
        true;
      if (
        this.props.primaryColumns[columnId].editInputType ===
          EditInputType.Dropdown &&
        !clientSideFiltering
      ) {
        return false;
      }
    }
    return this.props.isLoading;
  };

  getDropdownOptionsLoading = () => {
    if (this.props.currentEditFocus) {
      const { columnId } = this.props.currentEditFocus;
      if (
        this.props.primaryColumns[columnId].editInputType ===
        EditInputType.Dropdown
      ) {
        return this.props.isLoading;
      }
    }
    return false;
  };

  getPageView() {
    const { pageSize, selectedRowIndices } = this.props;
    const editOverrides = this.props.editOverrides ?? {};
    const filteredTableData = Array.isArray(this.props.filteredTableData)
      ? this.props.filteredTableData
      : [];

    const tableColumns = this.getReactTableColumns() || [];
    const transformedData = TableWidget.transformData(
      filteredTableData || [],
      this.props.filteredOrderMap || [],
      tableColumns,
      editOverrides,
    );
    this.assignTagColors(transformedData, tableColumns);
    let pageNo = this.props.pageNo;

    if (pageNo === undefined) {
      pageNo = 1;
      this.props.updateWidgetMetaProperty("pageNo", pageNo);
    }
    const { componentWidth, componentHeight } = getComponentDimensions(
      this.props,
    );
    // TODO layouts what to do for fill-parent?
    const isHeightFitContent = this.props.height.mode === "fitContent";
    const numEdits =
      Object.values(editOverrides).reduce((count, edits) => {
        return count + Object.keys(edits).length;
      }, 0) +
      Object.keys(this.props.deletedRowIndices ?? {}).length +
      Object.keys(this.props.inserts?.insertedRowsById ?? {}).length;
    const numEditErrors = this.props.validationErrors?.length ?? 0;

    const minHeight = getApplicableMinHeight(this.props);
    const maxHeight = getApplicableMaxHeight(this.props);

    return (
      <TableManagerContext.Provider value={this.tableManager}>
        <Suspense fallback={<Skeleton />}>
          <ReactTableComponent
            height={isHeightFitContent ? undefined : componentHeight}
            minHeight={
              minHeight
                ? Dimension.toPx(
                    minHeight,
                    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
                  ).value
                : undefined
            }
            maxHeight={
              maxHeight
                ? Dimension.toPx(
                    maxHeight,
                    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
                  ).value
                : undefined
            }
            width={componentWidth}
            backgroundColor={this.props.backgroundColor}
            borderRadius={this.props.borderRadius}
            border={this.props.border}
            selectedRowBackgroundColor={this.props.selectedRowBackgroundColor}
            rawTableData={this.props.tableDataWithInserts}
            tableData={transformedData}
            columns={tableColumns}
            isLoading={this.getIsLoading()}
            widgetId={this.props.widgetId}
            widgetName={this.props.widgetName}
            searchKey={this.props.searchText}
            columnOrder={this.props.columnOrder}
            triggerRowSelection={this.props.triggerRowSelection}
            columnSizeMap={this.props.columnSizeMap}
            pageNo={pageNo}
            pageSize={Math.max(1, pageSize)}
            pageType={this.props.pageType || PaginationTypes.CLIENT_SIDE}
            onCommandClick={(action: MultiStepDef, onComplete: () => void) =>
              this.onCommandClick(selectedRowIndices[0], action, onComplete)
            }
            selectedRowIndex={
              this.props.multiRowSelection
                ? -1
                : (this.props.selectedRowIndex ?? -1)
            }
            multiRowSelection={this.props.multiRowSelection}
            selectedRowIndices={this.getComputedSelectedRowIndices()}
            onRowClick={this.handleRowClick}
            onRowSelect={this.handleRowSelect}
            selectRows={this.selectRows}
            nextPageClick={this.handleNextPageClick}
            prevPageClick={this.handlePrevPageClick}
            handleResizeColumn={this.handleResizeColumn}
            updatePageNo={this.updatePageNumber}
            handleReorderColumn={this.handleReorderColumn}
            disableDrag={this.disableDrag}
            searchTableData={this.handleSearchTable}
            filters={this.props.filters}
            setColumnFilter={this.handleSetColumnFilter}
            compactMode={this.props.compactMode || CompactModeTypes.DEFAULT}
            showColumnBorders={this.props.showColumnBorders}
            sortTableColumn={this.handleColumnSorting}
            isSortable={this.props.pageType !== PaginationTypes.SERVER_SIDE}
            isSearchable={this.props.isSearchable}
            isDownloadable={this.props.isDownloadable}
            isFilterable={this.props.isFilterable}
            tableHeader={this.props.tableHeader}
            hiddenColumns={this.props.hiddenColumns ?? []}
            columnFreezes={this.props.columnFreezes ?? {}}
            numEdits={numEdits}
            numEditErrors={numEditErrors}
            validationMessages={this.props.validationErrors}
            onEditSave={this.handleEditSave}
            onEditCancel={this.handleEditCancel}
            searchPlaceholder={this.props.searchPlaceholder ?? "Search"}
            handleClickOutsideCells={this.handleClickOutside}
            onRowDeletion={this.handleRowDeletion}
            getIsRowDeleted={this.getIsRowDeleted}
            getIsRowInserted={this.getIsRowInserted}
            getIsRowEdited={this.getIsRowEdited}
            isDeletionEnabled={this.props.enableRowDeletion}
            onRowInsertion={this.handleRowInsertion}
            isInsertionEnabled={this.props.enableRowInsertion}
            isSaving={this.state.isSaving}
            getCellBackground={this.getCellBackground}
            onKeyDown={this.handleKeyDown}
            tableCellTextStyle={this.props.cellProps?.textStyle}
            tableHeaderTextStyle={this.props.headerProps?.textStyle}
            columnHeaderTextStyle={this.props.columnHeaderProps?.textStyle}
            searchProps={this.props.searchProps}
            disableNudge={this.handleDisableNudge}
            setCurrentCellFocus={this.setCurrentCellFocus}
            currentEditFocus={this.props.currentEditFocus}
            handleCopy={this.handleCopy}
            handlePaste={this.handlePaste}
          />
        </Suspense>
      </TableManagerContext.Provider>
    );
  }

  handleReorderColumn = (columnOrder: string[]) => {
    if (this.props.appMode === APP_MODE.EDIT) {
      this.updateWidgetProperties({ columnOrder });
    } else {
      this.props.updateWidgetMetaProperty("columnOrder", columnOrder);
    }
  };

  handleColumnSorting = (column: string, asc: boolean) => {
    this.resetSelectedRowIndex();
    // Keeping selected rows for a multiselect table results in rows selected by index,
    // just clear them to maintain consistent UX.
    if (this.props.multiRowSelection) {
      this.selectRows("NONE");
    }
    if (column === "") {
      this.props.updateWidgetMetaProperty("sortedColumn", undefined);
    } else {
      this.props.updateWidgetMetaProperty("sortedColumn", {
        column: column,
        asc: asc,
      });
    }
  };

  handleResizeColumn = (columnSizeMap: { [key: string]: number }) => {
    const resizeMap: { [key: string]: number } = {};
    const oldColumnSizeMap = this.props.columnSizeMap;
    const fluidColumnWidth = getFluidColumnWidth(
      getTableInnerDimensions(this.props).componentWidth,
      this.props.primaryColumns,
      oldColumnSizeMap,
    );
    Object.entries(this.props.primaryColumns).forEach(([id, col]) => {
      if (col.id in columnSizeMap) {
        if (!oldColumnSizeMap || !(col.id in oldColumnSizeMap)) {
          const actualFlexWidth = fluidColumnWidth;
          // Column from flex to fixed
          const delta =
            (columnSizeMap[col.id] - DEFAULT_TABLE_COLUMN_WIDTH) /
            this.props.canvasScaleFactor;
          const deltaRatio =
            (actualFlexWidth - TABLE_COLUMN_MIN_WIDTH) /
            (DEFAULT_TABLE_COLUMN_WIDTH - TABLE_COLUMN_MIN_WIDTH);
          resizeMap[col.id] =
            actualFlexWidth + delta * deltaRatio * this.props.canvasScaleFactor;
        } else {
          //fixed column resize
          resizeMap[col.id] =
            oldColumnSizeMap[col.id] +
            (columnSizeMap[col.id] - oldColumnSizeMap[col.id]) /
              this.props.canvasScaleFactor;
        }
        resizeMap[col.id] = Math.max(resizeMap[col.id], TABLE_COLUMN_MIN_WIDTH);
      }
    });
    if (this.props.appMode === APP_MODE.EDIT) {
      this.updateWidgetProperties({ columnSizeMap: resizeMap });
    } else {
      this.props.updateWidgetMetaProperty("columnSizeMap", resizeMap);
    }
  };

  handleSearchTable = (searchKey: any) => {
    const { onSearchTextChanged } = this.props;
    this.resetSelectedRowIndex();
    this.props.updateWidgetMetaProperty("pageNo", 1);
    const row = this.getSelectedRow(
      this.props.filteredTableData,
      this.props.selectedRowIndex,
    );
    this.props.updateWidgetMetaProperty(
      "searchText",
      searchKey,
      createRunEventHandlersPayload({
        steps: onSearchTextChanged,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_SEARCH,
        entityName: this.props.widgetName,
        additionalNamedArguments: {
          row,
          rowIndex: this.props.selectedRowIndex
            ? this.getCurrentRowIndex(this.props.selectedRowIndex)
            : undefined,
          // legacy property
          currentRow: row,
        },
      }),
    );
  };

  assignTagColors(
    tableData: Array<Record<string, unknown>>,
    columns: ReactTableColumnProps[],
  ) {
    let updatedColorAssignment = this.props.tagsColorAssignment ?? {
      uniqueTagsCount: 0,
      mapping: {},
    };
    let hasTags = false;
    for (const row of tableData) {
      if (!row) continue;
      for (const column of columns) {
        const value = row[column.columnProperties.id];
        if (
          column.metaProperties?.type === ColumnTypes.TAGS &&
          isArray(value)
        ) {
          hasTags = true;
          for (const tagValue of value) {
            const tagValueNorm = sanitizeCellValue(tagValue);
            let uniqValueIdx = updatedColorAssignment?.mapping[tagValueNorm];
            if (uniqValueIdx === undefined) {
              // optimization: if we have already created a copy of the tagsColorAssignment object, do not copy it again
              if (updatedColorAssignment === this.props.tagsColorAssignment) {
                updatedColorAssignment = {
                  uniqueTagsCount: updatedColorAssignment?.uniqueTagsCount ?? 0,
                  mapping: { ...updatedColorAssignment?.mapping },
                };
              }
              uniqValueIdx = updatedColorAssignment.uniqueTagsCount;
              // updatedColorAssignment is our own private copy here, we can modify it in-place
              updatedColorAssignment.uniqueTagsCount = uniqValueIdx + 1;
              updatedColorAssignment.mapping = {
                ...updatedColorAssignment?.mapping,
                [tagValueNorm]: uniqValueIdx,
              };
            }
          }
        }
      }
    }
    // at the beginning of this routine, updatedColorAssignment was assigned to be the same object as this.props.tagsColorAssignment
    // if they do not point to the same object anymore anymore, that means that the color assignments were updated in the loop above
    if (hasTags && updatedColorAssignment !== this.props.tagsColorAssignment) {
      this.props.updateWidgetMetaProperty(
        "tagsColorAssignment",
        updatedColorAssignment,
      );
    }
  }

  onCommandClick(
    rowIndex: number,
    action: MultiStepDef,
    onComplete: () => void,
  ) {
    try {
      const row = this.props.filteredTableData?.[rowIndex];
      const steps = (action ?? [])?.flatMap((step) => {
        return step;
      });
      const callbackId = addNewPromise(onComplete);
      this.runEventHandlers({
        steps,
        type: EventType.ON_CLICK,
        callbackId,
        additionalNamedArguments: {
          row,
          rowIndex: this.props.selectedRowIndex
            ? this.getCurrentRowIndex(this.props.selectedRowIndex)
            : undefined,
          // legacy property
          currentRow: row,
        },
      });
    } catch (error) {
      log.debug("Error parsing row action");
      log.debug(error);
    }
  }

  selectRows = (rows: "ALL" | "NONE" = "ALL") => {
    this.context.updateWidgetMetaProperty?.(
      this.props.widgetId,
      "selectedRowIndices",
      rows === "NONE"
        ? []
        : Array.from(Array(this.props.filteredTableData?.length ?? 0).keys()),
    );
    this.context.updateWidgetMetaProperty?.(
      this.props.widgetId,
      "selectedRows",
      rows === "NONE" ? [] : (this.props.filteredTableData ?? []),
    );
    if (rows === "ALL" && this.props.onRowSelected) {
      const row = this.getSelectedRow(
        this.props.filteredTableData,
        this.props.selectedRowIndex,
      );
      this.runEventHandlers?.({
        steps: this.props.onRowSelected,
        type: EventType.ON_CELL_SELECTED,
        additionalNamedArguments: {
          row,
          rowIndex: this.props.selectedRowIndex
            ? this.getCurrentRowIndex(this.props.selectedRowIndex)
            : undefined,
          // legacy property
          currentRow: row,
        },
      });
    }
  };

  handleRowSelect = (index: number, shiftKey?: boolean) => {
    let updatedSelectedRowIndices: Array<number> = [];
    let updatedSelectedRows;
    if (this.props.multiRowSelection) {
      if (shiftKey) {
        // need to handle selecting multiple rows
        const selectedRowIndices = isArray(this.props.selectedRowIndices)
          ? [...this.props.selectedRowIndices]
          : [this.props.selectedRowIndices];
        const currentSelectedRowIndex = this.props.selectedRowIndex ?? index;
        // select all rows between index and current row index (inclusive)
        const minIndex = Math.min(index, currentSelectedRowIndex);
        const maxIndex = Math.max(index, currentSelectedRowIndex);
        const newSelectedRowIndices = Array.from(
          { length: maxIndex - minIndex + 1 },
          (_, i) => i + minIndex,
        );
        if (
          newSelectedRowIndices.every((i) => selectedRowIndices.includes(i))
        ) {
          // unselect newSelectedRowIndices
          updatedSelectedRowIndices = selectedRowIndices.filter(
            (i) => !newSelectedRowIndices.includes(i),
          );
        } else {
          updatedSelectedRowIndices = Array.from(
            new Set(selectedRowIndices.concat(newSelectedRowIndices)),
          );
        }

        updatedSelectedRows = this.props.filteredTableData?.filter(
          (item: Record<string, unknown>, i: number) => {
            return updatedSelectedRowIndices.includes(i);
          },
        );
      } else {
        updatedSelectedRowIndices = isArray(this.props.selectedRowIndices)
          ? [...this.props.selectedRowIndices]
          : [this.props.selectedRowIndices];
        if (updatedSelectedRowIndices.includes(index)) {
          const rowIndex = updatedSelectedRowIndices.indexOf(index);
          updatedSelectedRowIndices.splice(rowIndex, 1);
        } else {
          updatedSelectedRowIndices.push(index);
        }
        updatedSelectedRows = this.props.filteredTableData?.filter(
          (item: Record<string, unknown>, i: number) => {
            return updatedSelectedRowIndices.includes(i);
          },
        );
      }
    } else {
      updatedSelectedRowIndices = [index];
      updatedSelectedRows = [this.props.filteredTableData?.[index]];
    }
    this.context.updateWidgetMetaProperties?.(this.props.widgetId, {
      selectedRowIndex: index,
      selectedRow: this.props.filteredTableData?.[index],
      selectedRowIndices: updatedSelectedRowIndices,
      selectedRows: updatedSelectedRows,
    });

    if (this.props.onRowSelected) {
      const row = this.getSelectedRow(this.props.filteredTableData, index);
      this.runEventHandlers?.({
        steps: this.props.onRowSelected,
        type: EventType.ON_CELL_SELECTED,
        additionalNamedArguments: {
          row,
          rowIndex: this.props.selectedRowIndex
            ? this.getCurrentRowIndex(this.props.selectedRowIndex)
            : undefined,
          // legacy property
          currentRow: row,
        },
      });
    }
  };

  handleRowClick = (rowData: Record<string, unknown>, index: number) => {
    if (this.props.onRowClicked) {
      const row = this.getSelectedRow(this.props.filteredTableData, index);
      this.runEventHandlers?.({
        steps: this.props.onRowClicked,
        type: EventType.ON_CLICK,
        additionalNamedArguments: {
          row,
          rowIndex: index ? this.getCurrentRowIndex(index) : undefined,
          // legacy property
          currentRow: row,
        },
      });
    }
  };

  updatePageNumber = (pageNo: number, event?: EventType) => {
    if (event) {
      const row = this.getSelectedRow(
        this.props.filteredTableData,
        this.props.selectedRowIndex,
      );
      this.props.updateWidgetMetaProperty(
        "pageNo",
        pageNo,
        createRunEventHandlersPayloadOptional({
          steps: this.props.onPageChange,
          currentScope: ApplicationScope.PAGE,
          type: event,
          entityName: this.props.widgetName,
          additionalNamedArguments: {
            row,
            rowIndex: this.props.selectedRowIndex
              ? this.getCurrentRowIndex(this.props.selectedRowIndex)
              : undefined,
            // legacy property
            currentRow: row,
          },
        }),
      );
    } else {
      this.props.updateWidgetMetaProperty("pageNo", pageNo);
    }
    if (this.props.onPageChange) {
      this.resetSelectedRowIndex();
    }
  };

  handleNextPageClick = () => {
    let pageNo = this.props.pageNo || 1;
    pageNo = pageNo + 1;
    const row = this.getSelectedRow(
      this.props.filteredTableData,
      this.props.selectedRowIndex,
    );
    this.props.updateWidgetMetaProperty(
      "pageNo",
      pageNo,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onPageChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_NEXT_PAGE,
        entityName: this.props.widgetName,
        additionalNamedArguments: {
          row,
          rowIndex: this.props.selectedRowIndex
            ? this.getCurrentRowIndex(this.props.selectedRowIndex)
            : undefined,
          // legacy property
          currentRow: row,
        },
      }),
    );
    if (this.props.onPageChange) {
      this.resetSelectedRowIndex();
    }
  };

  resetSelectedRowIndex = () => {
    // We currently do not support multi row selection in tables
    // and the onSelectedRow action isn't designed for it right now
    if (!this.props.multiRowSelection) {
      this.updateDefaultSelectedRow(this.props.filteredTableData);
    }
  };

  handlePrevPageClick = () => {
    let pageNo = this.props.pageNo || 1;
    pageNo = pageNo - 1;
    if (pageNo >= 1) {
      const row = this.getSelectedRow(
        this.props.filteredTableData,
        this.props.selectedRowIndex,
      );
      this.props.updateWidgetMetaProperty(
        "pageNo",
        pageNo,
        createRunEventHandlersPayloadOptional({
          steps: this.props.onPageChange,
          currentScope: ApplicationScope.PAGE,
          type: EventType.ON_PREV_PAGE,
          entityName: this.props.widgetName,
          additionalNamedArguments: {
            row,
            rowIndex: this.props.selectedRowIndex
              ? this.getCurrentRowIndex(this.props.selectedRowIndex)
              : undefined,
            // legacy property
            currentRow: row,
          },
        }),
      );
      if (this.props.onPageChange) {
        this.resetSelectedRowIndex();
      }
    }
  };

  handleSetColumnFilter = (columnId: string, filter: ColumnFilter) => {
    this.resetSelectedRowIndex();
    const filters: TableFilters = {
      ...this.props.filters,
      byColumn: {
        ...this.props.filters?.byColumn,
        [columnId]: filter,
      },
    };
    const row = this.getSelectedRow(
      this.props.filteredTableData,
      this.props.selectedRowIndex,
    );
    this.props.updateWidgetMetaProperty(
      "filters",
      filters,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onFiltersChanged,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_FILTERS_CHANGED,
        entityName: this.props.widgetName,
        additionalNamedArguments: {
          row,
          rowIndex: this.props.selectedRowIndex
            ? this.getCurrentRowIndex(this.props.selectedRowIndex)
            : undefined,
          // legacy property
          currentRow: row,
        },
      }),
    );
  };

  setCurrentCellFocus = (focus: { row: number; columnId: string }[]) => {
    this.currentCellFocus = focus;
    const originalRowIndex =
      this.props.filteredOrderMap?.[focus[0]?.row] || focus[0]?.row;
    const columnId: string | undefined = focus[0]?.columnId;

    if (
      this.props.currentEditFocus &&
      (focus.length > 1 ||
        originalRowIndex !== this.props.currentEditFocus.row ||
        columnId !== this.props.currentEditFocus.columnId)
    ) {
      this.context.updateWidgetMetaProperty?.(
        this.props.widgetId,
        "currentEditFocus",
        undefined,
      );
    }
  };

  clearCellFocus = () => {
    this.currentCellFocus = [];
  };

  handleClickOutside = () => {
    this.handleDisableNudge(false);
    if (this.props.currentEditFocus) {
      // if it's a dropdown, stop editing
      if (
        this.props.primaryColumns[this.props.currentEditFocus.columnId]
          .editInputType === EditInputType.Dropdown
      ) {
        this.handleEditStop({
          shouldSave: true,
          value: this.props.currentEditValue,
          validationErrors: [],
        });
      }
    } else if (this.currentCellFocus.length > 0) {
      this.clearCellFocus();
    }
  };

  getTopVisibleIndex = () => {
    const page = this.props.pageNo || 1;
    const pageSize = this.props.pageSize || 1;
    const isPaginated = this.props.pageType !== PaginationTypes.NONE;
    return isPaginated ? (page - 1) * pageSize : 0;
  };

  getBottomVisibleIndex = () => {
    const allEdits = this.props.allEdits ?? [];
    const page = this.props.pageNo || 1;
    const pageSize = this.props.pageSize || 1;
    const isPaginated = this.props.pageType !== PaginationTypes.NONE;
    return isPaginated
      ? Math.min(allEdits.length - 1, page * pageSize - 1)
      : allEdits.length - 1;
  };

  handlePaste = (event: Event) => {
    if (!(event instanceof ClipboardEvent)) return;
    const { currentEditFocus } = this.props;
    const currentCellFocus =
      this.currentCellFocus.length === 1 && this.currentCellFocus[0];
    if (currentCellFocus && !currentEditFocus) {
      const row = this.getOriginalRowIndex(currentCellFocus.row);
      const columnId = currentCellFocus.columnId;
      const isInserted = this.getIsRowInserted(row);
      const isDeleted = this.getIsRowDeleted(row);
      const columnProperties = this.props.primaryColumns[columnId];
      const isEditable =
        !isDeleted &&
        (isInserted
          ? columnProperties.isEditableOnInsertion
          : columnProperties.isEditable);
      if (!isEditable) return;
      const clipboardData = event.clipboardData;
      const clipboardText = clipboardData?.getData("text");
      // If clipboardText is an empty string, it means that the user has copied
      // an empty cell. In this case, we should allow the user to paste an empty
      // string into the cell.
      if (clipboardText === "" || clipboardText) {
        this.handleEditStart(row, columnId, clipboardText);
        event.preventDefault();
        event.stopPropagation();

        // NB: This validation logic mirrors the logic in `validateValue` in EditCell.tsx.
        // This is a bit of a hack because pasting the clipboard text in the way that we are
        // doing it here does not trigger the validation logic in `EditCell.tsx`.
        const errors: Record<string, string | undefined> =
          derivedInputFunctions.getValidationErrors({
            inputType: this.props.primaryColumns[columnId].editInputType,
            text: clipboardText ?? "",
            emailRegex: new RegExp(`^${EmailRegexString}$`),
            customValidationRule: String(
              columnProperties.editCustomValidationRule,
            ),
            customErrorMessage: columnProperties.editCustomErrorMessage,
            maxLength: columnProperties.editMaxLength,
            minLength: columnProperties.editMinLength,
            isRequired: isInserted
              ? columnProperties.isRequiredOnInsertion
              : columnProperties.editIsRequired,
          });
        const validationErrors = Object.values(errors).filter(
          Boolean,
        ) as string[];
        this.handleEditStop({
          shouldSave: true,
          value: this.props.currentEditValue,
          validationErrors,
        });
      }
    }
  };

  handleCopy = (event: Event) => {
    if (
      this.currentCellFocus.length === 0 ||
      !(event instanceof ClipboardEvent) ||
      this.props.currentEditFocus
    ) {
      return;
    }

    const orderedColIds = getVisibleOrderedColumnIds(
      this.props.primaryColumns,
      this.props.columnOrder ?? [],
      this.props.columnFreezes,
      this.props.multiRowSelection,
      this.props.hiddenColumns,
    );

    const data: any[][] = [];

    let largestRow = 0;

    const isMultiPage =
      this.props.pageType === PaginationTypes.SERVER_SIDE ||
      this.props.pageType === PaginationTypes.CLIENT_SIDE;
    const page = this.props.pageNo || 1;
    const pageSize = this.props.pageSize || 1;

    for (const cell of this.currentCellFocus ?? []) {
      const rowIndex = this.getOriginalRowIndex(cell.row);
      let rowValueIndex = rowIndex;
      if (isMultiPage) {
        rowValueIndex = rowIndex + (page - 1) * pageSize;
      }
      if (!Array.isArray(data[rowIndex])) {
        data[rowIndex] = [];
      }
      let cellValue = this.getCellValue(rowValueIndex, cell.columnId, true);
      if (isArray(cellValue) && cellValue.length > 1) {
        try {
          cellValue = JSON.stringify(cellValue);
        } catch {
          // ignore
        }
      }
      data[rowIndex][orderedColIds.indexOf(cell.columnId)] = cellValue;

      if (rowIndex > largestRow) {
        largestRow = rowIndex;
      }
    }

    event.clipboardData?.setData(
      "text",
      collapseTableData(data, largestRow)
        .map((row) => row.join("\t"))
        .join("\n"),
    );
    event.preventDefault();
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    const { currentEditFocus } = this.props;
    const currentCellFocus =
      this.currentCellFocus && this.currentCellFocus.length === 1
        ? this.currentCellFocus[0]
        : undefined;
    let needToStopPropagation = false;

    switch (event.key) {
      case "Enter": {
        // if not editing something and there is a focused cell, start editing it
        // first check if column is editable
        // if it's a boolean edit cell that is focued, toggle it but dont set the edit focus
        const { columnId } = currentCellFocus ?? {};
        const currentRowIndex = currentCellFocus?.row;
        const originalRowIndex = this.getOriginalRowIndex(currentRowIndex);
        if (columnId === TABLE_MULTISELECT_COLID && currentRowIndex != null) {
          // select/unselect this row
          this.handleRowSelect(currentRowIndex);
        } else if (
          currentCellFocus &&
          columnId &&
          this.props.primaryColumns?.[columnId]?.editInputType ===
            EditInputType.Checkbox
        ) {
          this.handleOneClickEdit(
            this.props.primaryColumns?.[columnId],
            originalRowIndex,
            !this.getCellValue(originalRowIndex, columnId),
          );
          needToStopPropagation = true;
        } else if (columnId && !currentEditFocus && currentCellFocus) {
          // start editing cell
          const isEditable = this.getIsRowInserted(originalRowIndex)
            ? this.props.primaryColumns?.[columnId]?.isEditableOnInsertion
            : this.props.primaryColumns?.[columnId]?.isEditable;
          if (!isEditable) {
            break;
          }
          this.handleEditStart(
            originalRowIndex,
            columnId,
            this.getCellValue(originalRowIndex, columnId) as string,
          );
          needToStopPropagation = true;
        }
        break;
      }
      case "Tab":
        if (currentCellFocus && currentEditFocus) {
          needToStopPropagation = false;

          const columnProps = this.getReactTableColumns().find(
            (col) => col.id === currentEditFocus.columnId,
          );

          // finish editing, moving focused happens inside the table component
          // we want to fire events if we have the column props we need
          if (columnProps) {
            this.onEditStop(
              columnProps.columnProperties,
              currentEditFocus.row,
              {
                shouldSave: true,
                value: this.props.currentEditValue,
              },
            );
          } else {
            this.handleEditStop({
              shouldSave: true,
              value: this.props.currentEditValue,
            });
          }
        }
        break;
      case "Escape":
        // if there is an edit cell, stop editing and dont save
        if (currentEditFocus) {
          this.handleEditStop({ shouldSave: false });
          needToStopPropagation = true;
        }
        break;
      default: {
        // Check for metaKey as well as ctrlKey for Mac/Windows support
        if (event.metaKey || event.ctrlKey) {
          break;
        }
        // if there is a focused cell but not an edit focus, start editing the focused cell
        // replace the value with the key pressed
        // check if event key is letter or number
        if (currentCellFocus && !currentEditFocus) {
          const { row, columnId } = currentCellFocus;
          const originalRowIndex = this.getOriginalRowIndex(row);
          const cellIsEditable =
            (this.getIsRowInserted(originalRowIndex) &&
              this.props.primaryColumns?.[columnId]?.isEditableOnInsertion) ||
            this.props.primaryColumns?.[columnId]?.isEditable;
          if (!cellIsEditable) {
            break;
          }
          const char = event.key.replace("Shift", "");
          if (!char || char.length > 1) {
            break;
          }
          this.handleEditStart(originalRowIndex, columnId, char);
          needToStopPropagation = true;
        }
      }
    }
    if (needToStopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleEditStart = (
    rowIndex: number,
    columnId: string,
    startingValue: string,
    overrideCurrentRowIndex?: number,
  ) => {
    // Disabling drag allows user seletion in the editing input without dragging the table
    this.disableDrag(true);
    this.handleDisableNudge(true);

    this.context.updateWidgetMetaProperties?.(this.props.widgetId, {
      currentEditValue: startingValue,
      currentEditFocus: { row: rowIndex, columnId },
    });
  };

  getCellValue = (
    originalRowIndex: number,
    columnId: string,
    includeEdits = true,
  ) => {
    if (this.getIsRowInserted(originalRowIndex)) {
      return this.props.inserts?.insertedRowsById?.[originalRowIndex]?.[
        columnId
      ];
    } else {
      if (
        includeEdits &&
        this.props.editOverrides?.[originalRowIndex]?.[columnId]?.value != null
      ) {
        return this.props.editOverrides[originalRowIndex][columnId].value;
      }
      return this.props.tableData?.[originalRowIndex]?.[columnId];
    }
  };

  // One-click edit is used for checkbox editing, where the cell is focused on hover
  // so handleEditStart/Stop being separate functions is unecessary
  handleOneClickEdit = (
    columnProperties: ColumnProperties,
    row: number,
    newValue: unknown,
  ) => {
    this.onEditStop(columnProperties, row, {
      shouldSave: true,
      value: newValue,
      validationErrors: [],
      overrideEditFocus: { row, columnId: columnProperties.id },
    });
  };

  handleEditChange = (
    value: any,
    dropdownOptions?: Array<{ label: string; value: string }>,
  ) => {
    this.context.updateWidgetMetaProperty?.(
      this.props.widgetId,
      "currentEditValue",
      value,
    );
    if (dropdownOptions && this.props.currentEditFocus?.columnId) {
      const columnId = this.props.currentEditFocus?.columnId;
      dropdownOptions.forEach((opt) => {
        this.onDropdownEdit(columnId, opt);
      });
    }
  };

  debouncedEditChange = debounce(this.handleEditChange, 100);

  onDropdownEdit = (
    columnId: string,
    selectedOption: { label: string; value: string },
  ) => {
    const columnProperties = this.props.primaryColumns[columnId];
    if (columnProperties.useLabelAsDisplayValue) {
      this.dropdownValueToLabelMaps[columnId] = {
        ...this.dropdownValueToLabelMaps[columnId],
        [selectedOption.value]: selectedOption.label,
      };
    }
  };

  onEditStop = (
    columnProperties: ColumnProperties,
    currentRowIndex: number,
    editStopProps: {
      shouldSave: boolean;
      value?: any;
      validationErrors?: Array<string>;
      overrideEditFocus?: { row: number; columnId: string };
      dropdownOption?: { label: string; value: string };
    },
  ) => {
    this.handleEditStop(editStopProps);

    const { value, shouldSave } = editStopProps;

    if (shouldSave) {
      const currentRow = {
        ...this.props.tableDataWithInserts?.[currentRowIndex],
        [columnProperties.id]: value,
      };

      if (columnProperties.editInputType === EditInputType.Dropdown) {
        this.runEventHandlers?.({
          steps: columnProperties.onOptionChange ?? [],
          type: EventType.ON_OPTION_CHANGE,
          additionalNamedArguments: {
            value,
            currentRowIndex,
            currentRow,
          },
        });
      } else if (columnProperties.editInputType === EditInputType.Checkbox) {
        this.runEventHandlers?.({
          steps: columnProperties.onCheckChange ?? [],
          type: EventType.ON_CHECK_CHANGE,
          additionalNamedArguments: {
            value,
            currentRowIndex,
            currentRow,
          },
        });
      } else if (columnProperties.editInputType === EditInputType.Date) {
        this.runEventHandlers?.({
          steps: columnProperties.onDateSelected ?? [],
          type: EventType.ON_DATE_SELECTED,
          additionalNamedArguments: {
            value,
            currentRowIndex,
            currentRow,
          },
        });
      } else {
        this.runEventHandlers?.({
          steps: columnProperties.onFocusOut ?? [],
          type: EventType.ON_BLUR,
          additionalNamedArguments: {
            value,
            currentRowIndex,
            currentRow,
          },
        });
      }
    }
  };

  handleEditStop = ({
    shouldSave,
    value,
    validationErrors,
    overrideEditFocus,
    dropdownOption,
  }: {
    shouldSave: boolean;
    value?: any;
    validationErrors?: Array<string>;
    overrideEditFocus?: { row: number; columnId: string };
    dropdownOption?: { label: string; value: string };
  }) => {
    if (!this.currentCellFocus) {
      this.disableDrag(false);
      this.handleDisableNudge(false);
    }
    let parsedValue = value;
    // if editing a number or a boolean, parse string back to number
    const editType = this.props.currentEditFocus?.columnId
      ? this.props.primaryColumns?.[this.props.currentEditFocus?.columnId]
          ?.editInputType
      : EditInputType.Text;
    if (editType && PARSED_INPUT_TYPES.includes(editType as EditInputType)) {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        // do nothing
      }
    }
    const columnId =
      overrideEditFocus?.columnId ?? this.props.currentEditFocus?.columnId;
    if (
      columnId &&
      this.props.currentEditDropdownSearchText !== "" &&
      this.props.currentEditDropdownSearchText
    ) {
      // We clear out the currentEditDropdownSearchText when we stop editing, and
      // fire off an onDropdownSearchTextChanged event.
      const onDropdownSearchTextChanged =
        this.props.primaryColumns[columnId].onDropdownSearchTextChanged;
      const clientSideFiltering =
        this.props.primaryColumns[columnId].editDropdownClientSideFiltering ??
        true;
      let callbackId: string | undefined;
      if (!clientSideFiltering) {
        this.suppressLoading = true;
        callbackId = addNewPromise(() => {
          setTimeout(() => {
            this.suppressLoading = false;
          }, 1000);
        });
      }
      this.props.updateWidgetMetaProperty?.(
        "currentEditDropdownSearchText",
        undefined,
        createRunEventHandlersPayloadOptional({
          steps: onDropdownSearchTextChanged,
          currentScope: ApplicationScope.PAGE,
          type: EventType.ON_TEXT_CHANGE,
          entityName: this.props.widgetName,
          callbackId: callbackId,
          additionalNamedArguments: {
            currentEditDropdownSearchText: "",
          },
        }),
      );
    }
    const row = overrideEditFocus?.row ?? this.props.currentEditFocus?.row;
    const updates: Record<string, any> = {};
    if (shouldSave && row != null && columnId != null) {
      const oldValue = this.getCellValue(row, columnId, false);
      const isInsertion = this.getIsRowInserted(row);
      if (!isEqual(oldValue, parsedValue)) {
        if (isInsertion) {
          const inserts = fastClone(this.props.inserts) ?? {};
          const originalRow = inserts?.insertedRowsById?.[row];
          if (originalRow) {
            originalRow[columnId] = parsedValue;
            if (inserts?.insertedRowValidations?.[row]) {
              inserts.insertedRowValidations[row][columnId] = validationErrors;
            } else {
              inserts.insertedRowValidations = {
                ...inserts.insertedRowValidations,
                [row]: { [columnId]: validationErrors },
              };
            }
            updates.inserts = inserts;
          }
        } else {
          const editOverrides = fastClone(this.props.editOverrides) ?? {};
          if (editOverrides[row] != null) {
            editOverrides[row][columnId] = {
              value: parsedValue,
              validationErrors,
            };
          } else {
            editOverrides[row] = {
              [columnId]: { value: parsedValue, validationErrors },
            };
          }
          updates.editOverrides = editOverrides;
        }
      } else if (
        !isInsertion &&
        columnId in (this.props.editOverrides?.[row] ?? {})
      ) {
        // the user has "un-edited" the value, remove it from the editOverrides object
        const editOverrides = fastClone(this.props.editOverrides) ?? {};
        delete editOverrides[row][columnId];
        updates.editOverrides = editOverrides;
      }
      // if it was a dropdown edit, call the onDropdownEdit callback
      if (
        columnId &&
        dropdownOption &&
        this.props.primaryColumns[columnId]?.editInputType ===
          EditInputType.Dropdown
      ) {
        this.onDropdownEdit(columnId, dropdownOption);
      }
    }
    if (!overrideEditFocus) {
      const row = this.props.currentEditFocus?.row;
      const columnId = this.props.currentEditFocus?.columnId;
      updates.currentEditFocus = undefined;
      updates.currentEditValue = undefined;
      if (row != null && columnId != null) {
        if (this.tableElement) {
          // without this, the table will lose focus after editing and prevent further keypresses
          setTimeout(() => {
            this.tableElement?.focus();
          });
        }
      }
    }
    this.context.updateWidgetMetaProperties?.(this.props.widgetId, updates);
  };

  handleEditCancel = () => {
    const callbackId = addNewPromise(() => {
      this.context.updateWidgetMetaProperties?.(this.props.widgetId, {
        currentEditFocus: undefined,
        currentEditValue: undefined,
        editOverrides: undefined,
        inserts: undefined,
        deletedRowIndices: undefined,
      });
      this.dropdownValueToLabelMaps = {};
    });
    this.runEventHandlers?.({
      steps: this.props.onCancelChanges ?? [],
      type: EventType.ON_CLICK,
      callbackId,
    });
  };

  handleEditSave = () => {
    const eventHandlerConfigured =
      this.props.onSaveChanges != null &&
      this.props.onSaveChanges.some((event) => (event as any).type != null);
    if (this.props.onSaveChanges && eventHandlerConfigured) {
      this.setState({
        isSaving: true,
      });
      const callbackId = addNewPromise(({ success }: any) => {
        this.setState({
          isSaving: false,
        });
        if (success) {
          this.context.updateWidgetMetaProperties?.(this.props.widgetId, {
            currentEditFocus: undefined,
            currentEditValue: undefined,
            editOverrides: undefined,
            inserts: undefined,
            deletedRowIndices: undefined,
          });
        }
      });
      this.runEventHandlers?.({
        steps: this.props.onSaveChanges,
        type: EventType.ON_CLICK,
        callbackId,
        additionalNamedArguments: {},
      });
      this.dropdownValueToLabelMaps = {};
    } else if (this.props.appMode === APP_MODE.EDIT) {
      sendInfoUINotification({
        message: `${this.props.widgetName} does not have an onSaveChanges event handler`,
        duration: 2,
      });
    }
  };

  handleRowDeletion = (currentIndices: number[]) => {
    const updates: Record<string, any> = {};
    const rows: Record<string, unknown>[] = [];
    currentIndices.forEach((currentIndex) => {
      const originalRowIndex = this.getOriginalRowIndex(currentIndex);
      const row = this.props.filteredTableData?.[currentIndex];
      if (row) {
        rows.push(row);
      }
      if (this.getIsRowInserted(originalRowIndex)) {
        const inserts = updates.inserts ?? fastClone(this.props.inserts) ?? {};
        const {
          insertedRowIdsByIndex,
          insertedRowsById,
          insertedRowValidations,
        } = inserts ?? {};
        const position = insertedRowsById?.[originalRowIndex]?.[
          "$relativePosition"
        ] as number;
        delete insertedRowsById?.[originalRowIndex];
        delete insertedRowValidations?.[originalRowIndex];
        if (insertedRowIdsByIndex && position != null) {
          insertedRowIdsByIndex[position] = [
            ...insertedRowIdsByIndex[position],
          ]?.filter((id) => id !== originalRowIndex);
        }
        updates.inserts = inserts;
      } else if (!this.props.deletedRowIndices && !updates.deletedRowIndices) {
        updates.deletedRowIndices = { [originalRowIndex]: true };
      } else {
        const deletedRowIndices = updates.deletedRowIndices ?? {
          ...this.props.deletedRowIndices,
        };
        if (deletedRowIndices[originalRowIndex]) {
          delete deletedRowIndices[originalRowIndex];
        } else {
          deletedRowIndices[originalRowIndex] = true;
        }
        updates.deletedRowIndices = deletedRowIndices;
      }
    });
    this.context.updateWidgetMetaProperties?.(this.props.widgetId, updates);

    if (
      (updates.inserts || updates.deletedRowIndices) &&
      this.props.multiRowSelection
    ) {
      this.selectRows("NONE");
    }

    if (this.props.onRowsDeleted) {
      this.runEventHandlers?.({
        steps: this.props.onRowsDeleted,
        type: EventType.ON_CLICK,
        additionalNamedArguments: {
          rowIndices: currentIndices,
        },
      });
    }
  };

  getIsRowDeleted = (rowIndex: number, isOriginalIndex = true) => {
    const originalRowIndex = isOriginalIndex
      ? rowIndex
      : this.getOriginalRowIndex(rowIndex);
    return Boolean(this.props.deletedRowIndices?.[originalRowIndex]);
  };

  getWidgetType(): WidgetType {
    return "TABLE_WIDGET";
  }

  insertRow = (
    defaultRow: Record<string, any>,
    validationErrors: Record<string, string[]>,
    options?: InsertRowOptions,
  ) => {
    const { inserts, tableData, filteredTableData } = this.props;
    const { beforeRowIndex, insertedBelow, isDuplication } = options || {};
    const { insertedRowsById, insertedRowIdsByIndex, insertedRowValidations } =
      inserts || {};
    // create a unique key for this row (equivalent to originalIndex for regular rows)
    const key = tableData.length + this.totalRowsInserted;
    this.totalRowsInserted += 1;

    // translate the beforeIndex to the originalIndex
    const beforeIndexToUse =
      beforeRowIndex != null
        ? this.getOriginalRowIndex(beforeRowIndex)
        : tableData.length;

    let relativePosition = beforeIndexToUse;

    // if the row is being inserted relative to another inserted row, use the relative position of that row
    // to correctly position this one
    if (this.getIsRowInserted(beforeIndexToUse)) {
      relativePosition = insertedRowsById?.[beforeIndexToUse]?.[
        "$relativePosition"
      ] as number;
    }

    // if relativePosition is larger than the length of the table data, insert it at the end
    if (relativePosition > tableData.length) {
      relativePosition = tableData.length;
    }

    const rowIndexToDuplicate =
      beforeRowIndex == null || !isDuplication ? undefined : beforeRowIndex - 1;
    const rowValues =
      rowIndexToDuplicate != null &&
      filteredTableData &&
      rowIndexToDuplicate < filteredTableData.length
        ? filteredTableData[rowIndexToDuplicate]
        : defaultRow;

    const updatedInsertedRowsById = {
      ...insertedRowsById,
      [key]: {
        ...rowValues,
        $originalIndex: key,
        $relativePosition: relativePosition,
      },
    };

    // determine the order of the inserted rows at this relative position
    // if it was inserted before/after another inserted row, insert it before that row
    // if it was inserted below an existing row, insert it at the beginning of the list
    // otherwise, insert it at the beginning of the list
    const insertPos = insertedRowIdsByIndex?.[relativePosition]?.findIndex(
      (id) => id === beforeIndexToUse,
    );
    const updatedInsertedRows = insertedRowIdsByIndex?.[relativePosition]
      ? [...insertedRowIdsByIndex[relativePosition]]
      : [];
    if (insertPos !== undefined && insertPos !== -1) {
      updatedInsertedRows.splice(insertPos, 0, key);
    } else if (insertedBelow) {
      // try to find the spot in the inserted rows where our NEW row should be inserted
      // falling back to previous behavior if we can't find it
      const rowIndexBeforeInsertion = (beforeRowIndex ?? 0) - 1;
      const targetInsertedRowIndex =
        this.props.filteredOrderMap?.[rowIndexBeforeInsertion] ?? -1;
      const relativeIndexInInsertedRows =
        insertedRowIdsByIndex?.[beforeIndexToUse]?.findIndex(
          (n) => n === targetInsertedRowIndex,
        ) ?? -1;
      if (relativeIndexInInsertedRows !== -1) {
        updatedInsertedRows.splice(relativeIndexInInsertedRows + 1, 0, key);
      } else {
        updatedInsertedRows.splice(0, 0, key); // insert at beginning of inserted rows
      }
    } else {
      updatedInsertedRows.push(key);
    }

    const updatedInsertedRowIdsByIndex = {
      ...insertedRowIdsByIndex,
      [relativePosition]: updatedInsertedRows,
    };

    const updatedInsertedRowValidations = {
      ...insertedRowValidations,
      [key]: validationErrors,
    };

    this.context.updateWidgetMetaProperty?.(this.props.widgetId, "inserts", {
      insertedRowsById: updatedInsertedRowsById,
      insertedRowIdsByIndex: updatedInsertedRowIdsByIndex,
      insertedRowValidations: updatedInsertedRowValidations,
    });

    // set the edit focus to the first editable column of the newly inserted row
    const columnId = (this.props.columnOrder ?? Object.keys(defaultRow)).find(
      (col) =>
        this.props.primaryColumns[col].isEditableOnInsertion &&
        this.props.primaryColumns[col].isVisible,
    );

    const rowIndex =
      beforeRowIndex == null
        ? (this.props.allEdits ?? this.props.tableData)?.length
        : isDuplication
          ? beforeRowIndex + 1
          : beforeRowIndex;

    if (columnId != null) {
      this.handleEditStart(
        key,
        columnId,
        defaultRow[columnId] as string,
        rowIndex,
      );
    }
    // if the row was inserted at the bottom of the table, make sure it's visible
    if (relativePosition === tableData.length) {
      setTimeout(() => {
        // If pagination is not enabled and the row is inserted at the end of the table, scroll to the bottom
        if (this.props.pageType === PaginationTypes.NONE) {
          const tableScroller = this.getTableScroller();
          tableScroller?.scrollTo({
            top: tableScroller?.scrollHeight + 40,
            behavior: "smooth",
          });
        } else {
          // move to the last page
          this.updatePageNumber(
            Math.ceil(
              this.props.tableDataWithInserts.length / this.props.pageSize,
            ),
          );
        }
      }, 300);
    }

    return rowIndex;
  };

  handleRowInsertion = (options?: InsertRowOptions) => {
    const { primaryColumns } = this.props;
    const validationErrors = Object.entries(primaryColumns).reduce(
      (acc: Record<string, string[]>, [key, column]) => {
        if (column.isRequiredOnInsertion && column.defaultValue === undefined) {
          acc[key] = ["This field is required"];
        }
        return acc;
      },
      {},
    );
    const toEvaluate = Object.entries(primaryColumns)
      .filter(([, column]) => column.defaultValue !== undefined)
      .map(([k, v]) => [k, v?.defaultValue]);
    let callbackId: string | undefined;
    let defaultRow: Record<string, any> = {};
    let rowIndex: number | undefined;
    if (toEvaluate.length === 0) {
      rowIndex = this.insertRow({}, validationErrors, options);
      this.runEventHandlers?.({
        steps: this.props.onRowInserted ?? [],
        type: EventType.ON_CLICK,
        additionalNamedArguments: {
          row: defaultRow,
          rowIndex,
        },
      });
    } else {
      callbackId = addNewPromise((response) => {
        defaultRow = toEvaluate.reduce((acc: Record<string, any>, [col], i) => {
          acc[col] = response[i];
          return acc;
        }, {});
        rowIndex = this.insertRow(defaultRow, validationErrors, options);

        this.runEventHandlers?.({
          steps: this.props.onRowInserted ?? [],
          type: EventType.ON_CLICK,
          additionalNamedArguments: {
            row: defaultRow,
            rowIndex,
          },
        });
      });
      this.context.evaluateBindings &&
        this.context.evaluateBindings(
          toEvaluate.map(([k, v]) => v),
          callbackId,
        );
    }
  };

  getTableScroller = () => {
    if (this.tableScroller) return this.tableScroller;

    if (!this.tableElement && !this.tableScroller) {
      this.tableScroller = document.querySelector(
        `#table-scroller-${this.props.widgetId}`,
      );
    } else if (!this.tableScroller) {
      this.tableScroller = this.tableElement?.querySelector(
        `#table-scroller-${this.props.widgetId}`,
      );
    }
    return this.tableScroller;
  };

  isNudgeDisabled = false;
  handleDisableNudge = (disableNudge: boolean) => {
    if (this.isNudgeDisabled === disableNudge) return;
    this.isNudgeDisabled = disableNudge;
    this.disableNudge(disableNudge);
  };
}

const mapStateToProps = (state: AppState, props: WidgetProps) => ({
  canvasScaleFactor: getResponsiveCanvasScaleFactor(state),
  isSelected: getIsWidgetSelected(state, props.widgetId),
  typographies: selectGeneratedThemeTypographies(state),
  isDeepBindingsFeatureFlagEnabled: selectFlagById(
    // TODO: remove after FF is on
    state,
    Flag.ENABLE_DEEP_BINDINGS_PATHS,
  ),
});

export default connect(mapStateToProps)(withMeta(TableWidget));
