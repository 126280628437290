import {
  Dimension,
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { selectWidgets } from "legacy/actions/widgetActions";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetTypes, SlideoutSize } from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  VALIDATION_TYPES,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  EMPTY_RADIUS,
} from "legacy/themes/constants";
import { generateClassName } from "legacy/utils/generators";
import { Flag } from "store/slices/featureFlags";
import { PropsPanelCategory } from "../../constants/PropertyControlConstants";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import WidgetFactory from "../Factory";
import { paddingProperty } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { WidgetLayoutProps } from "../shared";
import { styleProperties } from "../styleProperties";
import withMeta, { WithMeta } from "../withMeta";
import SlideoutComponent from "./SlideoutComponent";

export default class SlideoutWidget extends BaseWidget<
  SlideoutWidgetProps & WithMeta,
  WidgetState
> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "Sections",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "sections",
            label: "Sections",
            headerControlType: "ADD_SECTION",
            controlType: "CHILD_LIST",
            useIndexedNames: false,
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          ...styleProperties({
            backgroundColorThemeValue: "{{ theme.colors.neutral }}",
            defaultBorderProperty: DEFAULT_CONTAINER_BORDER_OBJECT,
            borderThemeValue: DEFAULT_CONTAINER_BORDER_OBJECT,
            borderRadiusThemeValue: () => {
              return {
                treatAsNull: true,
                value: EMPTY_RADIUS,
              };
            },
            defaultBorderRadiusProperty: ({ theme }) => {
              const withRadius = theme?.borderRadius ?? Dimension.px(4);
              const zeroRadius = Dimension.px(0);
              return {
                topRight: zeroRadius,
                topLeft: withRadius,
                bottomLeft: withRadius,
                bottomRight: zeroRadius,
              };
            },
          }),
          {
            propertyName: "canOutsideClickClose",
            label: "Quick dismiss",
            helpText:
              "Allows dismissing the slideout when a user presses the escape key or clicks the backdrop",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "hasBackdrop",
            label: "Backdrop",
            helpText: "Enables a backdrop behind the slideout",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "size",
            label: "Width",
            helpText: "The slideout width when open",
            controlType: "DROP_DOWN",
            defaultValue: SlideoutSize.MEDIUM,
            options: [
              {
                label: "Extra small",
                value: SlideoutSize.EXTRA_SMALL,
              },
              {
                label: "Small",
                value: SlideoutSize.SMALL,
              },
              {
                label: "Medium",
                value: SlideoutSize.MEDIUM,
              },
              {
                label: "Large",
                value: SlideoutSize.LARGE,
              },
              {
                label: "Fullscreen",
                value: SlideoutSize.FULLSCREEN,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          paddingProperty({}),
        ],
      },
      {
        sectionName: "Routes",
        sectionCategory: PropsPanelCategory.Routing,
        hidden(props, path, flags) {
          return !flags[Flag.MULTIPAGE_SLIDEOUTS];
        },
        children: [
          {
            propertyName: "routes",
            label: "Routes",
            headerControlType: "ADD_ROUTE",
            controlType: "ROUTE_LIST",
            helpText: "The URL routes that will be mapped to this slideout",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onOpen",
            "Triggers an action when the slideout is opened",
          ),
          getPopoverConfig(
            "onClose",
            "Triggers an action when the slideout is closed",
          ),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isOpen: false,
      isVisible: false,
    };
  }

  renderChildWidget = (childWidgetData: WidgetLayoutProps): ReactNode => {
    return WidgetFactory.createWidget(childWidgetData, this.props.appMode);
  };

  closeSlideout = (e: any) => {
    // TODO(abhinav): Create a static property with is a map of widget properties
    // Populate the map on widget load
    this.props.updateWidgetMetaProperty("isClosing", true);
    this.props.updateWidgetMetaProperty("isOpen", false);
    e.stopPropagation();
    e.preventDefault();
  };

  finishClose = () => {
    this.props.updateWidgetMetaProperty("isVisible", false);
    this.props.updateWidgetMetaProperty("isClosing", false);
    this.props.hidePropertyPane();

    if (this.props.onClose) {
      super.runEventHandlers({
        steps: this.props.onClose,
        type: EventType.ON_SLIDEOUT_CLOSE,
      });
    }
  };

  onOpen = () => {
    this.props.updateWidgetMetaProperty("isOpen", true);
    if (this.props.onOpen) {
      super.runEventHandlers({
        steps: this.props.onOpen,
        type: EventType.ON_SLIDEOUT_OPEN,
      });
    }
  };

  getChildren(): ReactNode {
    if (this.props.children && this.props.children.length > 0) {
      const children = this.props.children.filter(Boolean);
      return children.length > 0 && children.map(this.renderChildWidget);
    }
  }

  stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  makeSlideoutComponent(content: ReactNode) {
    return (
      <div onClick={this.stopPropagation}>
        <SlideoutComponent
          {...this.props}
          isOpen={!!this.props.isOpen}
          isClosing={!!this.props.isClosing}
          isVisible={!!this.props.isVisible}
          size={(this.props.size as SlideoutSize) || SlideoutSize.MEDIUM}
          onClose={this.closeSlideout}
          afterVisibleChange={(visible) => {
            visible ? this.onOpen() : this.finishClose();
          }}
          className={`t--slideout-widget ${generateClassName(
            this.props.widgetId,
          )}`}
          hasBackdrop={!!this.props.hasBackdrop}
          canOutsideClickClose={!!this.props.canOutsideClickClose}
          appMode={this.props.appMode}
          firstSectionId={this.props.children?.[0]?.widgetId}
          childrenWidgets={this.props.children}
          styleProps={{
            padding: this.props.padding,
            border: this.props.border,
            borderRadius: this.props.borderRadius,
            backgroundColor: this.props.backgroundColor,
            fallbackBorderColorThemeKey: "neutral100",
            fallbackBackgroundColorThemeKey: "neutral",
          }}
          internalWidth={this.props.internalWidth}
        >
          {content}
        </SlideoutComponent>
      </div>
    );
  }

  getPageView() {
    const children = this.getChildren();
    return this.makeSlideoutComponent(children);
  }

  getWidgetType() {
    return WidgetTypes.SLIDEOUT_WIDGET;
  }
}

export interface SlideoutWidgetProps extends WidgetPropsRuntime, WithMeta {
  appMode: APP_MODE;
  isOpen?: boolean;
  isClosing?: boolean;
  children?: WidgetPropsRuntime[];
  canOutsideClickClose?: boolean;
  hidePropertyPane: (widgetId?: string) => void;
  size: SlideoutSize;
  hasBackdrop: boolean;
  onOpen?: MultiStepDef;
  onClose?: MultiStepDef;
  // style properties
  backgroundColor?: string;
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
  padding?: Padding;
}

const mapDispatchToProps = (dispatch: any) => ({
  hidePropertyPane: () => {
    dispatch(selectWidgets([]));
  },
});

export const ConnectedSlideoutWidget = connect(
  null,
  mapDispatchToProps,
)(withMeta(SlideoutWidget));
