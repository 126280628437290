import { ApplicationScope } from "@superblocksteam/shared";
import React, { Suspense, lazy } from "react";
import ReactPlayer from "react-player";
import shallowEqual from "shallowequal";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { retryPromise } from "legacy/utils/Utils";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import withMeta, { WithMeta } from "../withMeta";
import VideoWidgetPropertyCategories from "./VideoWidgetPropertyCategories";

const VideoComponent = lazy(() =>
  retryPromise(() => import("./VideoComponent")),
);

enum PlayState {
  NOT_STARTED = "NOT_STARTED",
  PAUSED = "PAUSED",
  ENDED = "ENDED",
  PLAYING = "PLAYING",
}

class VideoWidget extends BaseWidget<VideoWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<VideoWidgetProps>[]
    | undefined {
    return VideoWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }
  private _player = React.createRef<ReactPlayer>();
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      url: VALIDATION_TYPES.TEXT,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      playState: PlayState.NOT_STARTED,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  shouldComponentUpdate(nextProps: VideoWidgetProps) {
    return !shallowEqual(nextProps, this.props);
  }

  getPageView() {
    const { url, autoPlay, onEnd, onPause, onPlay, isLoading } = this.props;

    if (isLoading) return <Skeleton />;

    return (
      <Suspense fallback={<Skeleton />}>
        <VideoComponent
          player={this._player}
          url={url}
          autoplay={autoPlay}
          controls={true}
          width="100%"
          height="100%"
          onPlay={() => {
            this.props.updateWidgetMetaProperty(
              "playState",
              PlayState.PLAYING,
              createRunEventHandlersPayloadOptional({
                steps: onPlay,
                currentScope: ApplicationScope.PAGE,
                type: EventType.ON_VIDEO_PLAY,
                entityName: this.props.widgetName,
              }),
            );
          }}
          onPause={() => {
            //TODO: We do not want the pause event for onSeek or onEnd.
            this.props.updateWidgetMetaProperty(
              "playState",
              PlayState.PAUSED,
              createRunEventHandlersPayloadOptional({
                steps: onPause,
                currentScope: ApplicationScope.PAGE,
                type: EventType.ON_VIDEO_PAUSE,
                entityName: this.props.widgetName,
              }),
            );
          }}
          onEnded={() => {
            this.props.updateWidgetMetaProperty(
              "playState",
              PlayState.ENDED,
              createRunEventHandlersPayloadOptional({
                steps: onEnd,
                currentScope: ApplicationScope.PAGE,
                type: EventType.ON_VIDEO_END,
                entityName: this.props.widgetName,
              }),
            );
          }}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return "VIDEO_WIDGET";
  }
}

export interface VideoWidgetProps extends WidgetPropsRuntime, WithMeta {
  url: string;
  autoPlay: boolean;
  onPause?: MultiStepDef;
  onPlay?: MultiStepDef;
  onEnd?: MultiStepDef;
  isLoading: boolean;
}

export default VideoWidget;
export const ConnectedVideoWidget = withMeta(VideoWidget);
