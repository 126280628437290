/**
 * Widget are responsible for accepting the abstraction layer inputs, interpreting them into renderable props and
 * spawning components based on those props
 * Widgets are also responsible for dispatching actions and updating the state tree
 */
import {
  OBS_TAG_ENTITY_ID,
  OBS_TAG_ENTITY_TYPE,
  OBS_TAG_ENTITY_NAME,
  OBS_TAG_WIDGET_TYPE,
  Dimension,
  ApplicationScope,
} from "@superblocksteam/shared";
import React, { Component, ReactNode } from "react";
import shallowEqual from "shallowequal";
import PositionedContainer from "legacy/components/designSystems/default/PositionedContainer";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import ErrorBoundary from "legacy/components/editorComponents/ErrorBoundry";
import {
  WidgetType,
  WidgetTypes,
  CanvasLayout,
  Position,
  WIDGET_CAN_HAVE_CHILDREN,
} from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import {
  CreateRunEventHandlersPayloadArgs,
  createRunEventHandlersPayload,
} from "legacy/utils/actions";
import AutoSizeContainerWrapper from "legacy/widgets/base/AutoSize/AutoSizeContainerWrapper";
import { eventNameToSpanName } from "tracing/utils";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import {
  getComponentErrorMessage,
  hasInvalidPropsInComponent,
} from "utils/error/error";
import ResizingStackItem from "../StackLayout/ResizingStackItem";
import {
  isInvisibleButExpandedInStack,
  isStackLayout,
} from "../StackLayout/utils";
import DraggableComponent from "../base/DraggableComponent";
import ExpandableVisibilityComponent from "../base/ExpandableVisibilityComponent";
import FocusableComponent from "../base/FocusableComponent";
import ResizableComponent from "../base/ResizableComponent/ResizableComponent";
import ResizableStackedComponent from "../base/ResizableComponent/ResizableStackedComponent";
import StickySectionComponent from "../base/StickySectionComponent";
import WidgetNameComponent from "../base/WidgetNameComponent";
import {
  WidgetProps,
  WidgetState,
  WidgetPropsRuntime,
  DynamicLayoutProps,
  DynamicVisibilityProperties,
  CopiedWidgets,
  PartialWidgetProps,
  NestedTextStylePropertyNames,
  WidgetPositionProps,
} from "./types";
import type { DerivedPropertiesMap } from "../Factory";
import type { WidgetOperation } from "../WidgetOperations";
import type { WithHeightOverride } from "../withComputedHeight";
import type { CopyInfo } from "../withWidgetProps";
import type { WidgetOperationPayloads } from "legacy/actions/pageActions";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";

// the type of the argument to runEventHandlers
export type RunWidgetEventHandlers = Omit<
  CreateRunEventHandlersPayloadArgs,
  "entityName"
>;

/***
 * BaseWidget
 *
 * The abstract class which is extended/implemented by all widgets.
 * Widgets must adhere to the abstractions provided by BaseWidget.
 *
 * Do not:
 * 1) Use the context directly in the widgets
 * 2) Update or access the dsl in the widgets
 * 3) Call actions in widgets or connect the widgets to the entity reducers
 *
 */
abstract class BaseWidget<
  T extends WidgetPropsRuntime & WithHeightOverride,
  K extends WidgetState,
> extends Component<T, K> {
  static contextType = EditorContext;
  context!: React.ContextType<typeof EditorContext>;

  // Needed to send a default no validation option. In case a widget needs
  // validation implement this in the widget class again
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return BASE_WIDGET_VALIDATION;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }
  // TODO Find a way to enforce this, (dont let it be set)
  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [];
  }

  static getNewPropertyPaneConfig(): PropertyPaneConfig[] | undefined {
    return undefined;
  }

  /**
   *  Widget abstraction to register the widget type
   *  ```javascript
   *   getWidgetType() {
   *     return "MY_AWESOME_WIDGET",
   *   }
   *  ```
   */
  abstract getWidgetType(): WidgetType;

  /**
   *  Widgets can execute actions using this `executeAction` method.
   *  Triggers may be specific to the widget
   */
  runEventHandlers(
    actionPayload: Omit<RunWidgetEventHandlers, "currentScope">,
  ) {
    const { runEventHandlers } = this.context;
    if (!runEventHandlers) return;

    const propertyPath = `${this.props.widgetName}.${eventNameToSpanName(
      actionPayload.type,
    )}`;

    const additionalEventAttributes = {
      [OBS_TAG_ENTITY_TYPE]: ENTITY_TYPE.WIDGET,
      [OBS_TAG_ENTITY_ID]: this.props.widgetId,
      [OBS_TAG_ENTITY_NAME]: this.props.widgetName,
      [OBS_TAG_WIDGET_TYPE]: this.getWidgetType(),
      pathToDisplay: propertyPath,
      propertyPath,
    };

    runEventHandlers(
      createRunEventHandlersPayload({
        ...actionPayload,
        // TODO(APP_SCOPE): update if/when components are part of app scope
        currentScope: ApplicationScope.PAGE,
        entityName: this.props.widgetName,
        additionalEventAttributes,
      }),
    );
  }

  disableDrag = (disable: boolean) => {
    const { disableDrag } = this.context;
    disableDrag && disable !== undefined && disableDrag(disable);
  };

  disableNudge = (disable: boolean) => {
    const { disableNudge } = this.context;
    disableNudge && disable !== undefined && disableNudge(disable);
  };

  updateWidget<Op extends WidgetOperation & keyof WidgetOperationPayloads>(
    operationName: Op,
    widgetId: string,
    widgetProperties: Omit<WidgetOperationPayloads[Op], "widgetId">,
  ): void {
    const { updateWidget } = this.context;
    updateWidget && updateWidget(operationName, widgetId, widgetProperties);
  }

  deleteWidgetProperty(propertyPaths: string[]): void {
    const { deleteWidgetProperty } = this.context;
    const { widgetId } = this.props;
    if (deleteWidgetProperty && widgetId) {
      deleteWidgetProperty(widgetId, propertyPaths);
    }
  }

  updateWidgetProperties(updates: Record<string, unknown>): void {
    const { updateWidgetProperties } = this.context;
    const { widgetId } = this.props;
    if (updateWidgetProperties && widgetId) {
      updateWidgetProperties(widgetId, updates);
    }
  }

  resetChildrenMetaProperty(widgetId: string) {
    const { resetChildrenMetaProperty } = this.context;
    resetChildrenMetaProperty?.(widgetId);
  }

  componentDidUpdate(prevProps: T) {}

  componentDidMount(): void {}

  getErrorInformation() {}

  render() {
    return this.getWidgetView();
  }

  makeResizable(content: ReactNode, layout?: CanvasLayout) {
    if (isStackLayout(layout)) {
      return (
        <ResizableStackedComponent
          {...this.props}
          paddingOffset={PositionedContainer.padding}
          hasInvalidProps={false}
        >
          {content}
        </ResizableStackedComponent>
      );
    }
    return (
      <ResizableComponent
        {...this.props}
        hasInvalidProps={false} // TODO: Add the logic back once highlighting behavior is finalized
      >
        {content}
      </ResizableComponent>
    );
  }

  makeResizableDynamicallySpaced(content: ReactNode) {
    return (
      <ResizingStackItem widgetProps={this.props} appMode={this.props.appMode}>
        {content}
      </ResizingStackItem>
    );
  }

  showWidgetName(content: ReactNode, showControls = false) {
    return (
      <React.Fragment>
        {content}
        {!this.props.disablePropertyPane && (
          <WidgetNameComponent
            {...this.props}
            widgetType={this.getWidgetType()}
            showNameOverride={showControls}
            hasInvalidProps={false} // TODO: Add the logic back once highlighting behavior is finalized
            errorMessage={""}
          />
        )}
      </React.Fragment>
    );
  }

  makeDraggable(content: ReactNode) {
    return <DraggableComponent {...this.props}>{content}</DraggableComponent>;
  }

  makeFocusable(content: ReactNode) {
    return <FocusableComponent {...this.props}>{content}</FocusableComponent>;
  }

  makePositioned(content: ReactNode) {
    return (
      <PositionedContainer
        widgetProps={this.props}
        appMode={this.props.appMode}
      >
        {content}
      </PositionedContainer>
    );
  }

  // This is currently unused, consider bringing this back later
  makeExpandableComponent(content: ReactNode) {
    return (
      <ExpandableVisibilityComponent {...this.props}>
        {content}
      </ExpandableVisibilityComponent>
    );
  }

  makeInvisibleButSpaced() {
    return (
      <PositionedContainer
        widgetProps={this.props}
        appMode={this.props.appMode}
      >
        <React.Fragment />
      </PositionedContainer>
    );
  }

  addErrorBoundary({
    content,
    isValid,
    widgetType,
    widgetId,
    getErrorInformation,
  }: {
    content: ReactNode;
    isValid: boolean;
    widgetType: string;
    widgetId: string;
    getErrorInformation: () => void | Record<string, string>;
  }) {
    return (
      <ErrorBoundary
        isValid={isValid}
        widgetType={widgetType}
        widgetId={widgetId}
        getErrorInformation={getErrorInformation}
      >
        {content}
      </ErrorBoundary>
    );
  }

  private getWidgetView(): ReactNode {
    let content = this.getWidgetComponent();

    content = this.addErrorBoundary({
      content,
      isValid:
        this.props.appMode === APP_MODE.EDIT
          ? this.props.isLoading || !this.hasInvalidProps()
          : true,
      widgetType: this.props.type,
      widgetId: this.props.widgetId,
      getErrorInformation: this.getErrorInformation.bind(this),
    });

    switch (this.props.appMode) {
      case APP_MODE.EDIT: {
        if (!this.props.detachFromLayout) {
          if (!this.props.resizeDisabled) {
            content = this.makeResizable(content, this.props.parentLayout);
          }
          content = this.showWidgetName(content);
          if (this.props.dragDisabled) {
            content = this.makeFocusable(content);
          } else {
            // Handles both drag and focus logic.
            content = this.makeDraggable(content);
          }
          content = this.makePositioned(content);
          if (
            !this.props.resizeDisabled &&
            isStackLayout(this.props.parentLayout)
          ) {
            // Resizing items inside of a stack is different than inside fixed grid because the resize may need to move siblings
            content = this.makeResizableDynamicallySpaced(content);
          }
        }
        return content;
      }
      case APP_MODE.PUBLISHED: {
        if (isInvisibleButExpandedInStack(this.props)) {
          return this.makeInvisibleButSpaced();
        } else if (this.props.isVisible) {
          if (!this.props.detachFromLayout) {
            content = this.makePositioned(content);
          }
          return content;
        }
        return <React.Fragment />;
      }
      default: {
        throw Error("AppMode not defined");
      }
    }
  }

  getWidgetComponent() {
    const { appMode } = this.props;

    let content =
      appMode === APP_MODE.EDIT ? this.getCanvasView() : this.getPageView();

    const isSection = this.props.type === WidgetTypes.SECTION_WIDGET;

    // For modal and slideout sections, we want to apply the auto height if not fill parent
    // to correct for any wrong fitContent calculations
    if (
      (this.props.detachFromLayout && !isSection) ||
      (isSection && this.props.height.mode === "fillParent")
    ) {
      return content;
    }

    // On the grid, let's round the height to the nearest grid unit
    // Let's not round container widgets
    const roundHeight =
      this.props.parentLayout === CanvasLayout.FIXED &&
      !WIDGET_CAN_HAVE_CHILDREN.includes(this.props.type as WidgetTypes);

    content = (
      <AutoSizeContainerWrapper
        heightOverride={this.props.computedHeight}
        isSection={isSection}
        onUpdateDynamicHeight={this.updateAutoHeight.bind(this)}
        onUpdateDynamicWidth={this.updateAutoWidth.bind(this)}
        widgetProps={this.props}
        roundHeight={roundHeight}
        dataTest={
          this.props.appMode === APP_MODE.EDIT
            ? undefined
            : `widget-${this.props.widgetName}` // Draggable component has the data test when in canvas mode
        }
      >
        {content}
      </AutoSizeContainerWrapper>
    );

    // Note: StickySectionComponent must be the last wrapper otherwise it will not work
    if (this.props.position === Position.STICKY) {
      content = (
        <StickySectionComponent {...this.props}>
          {content}
        </StickySectionComponent>
      );
    }

    return content;
  }

  updateAutoHeight(height: number): void {
    const update = this.context.updateWidgetAutoHeight;
    if (update) {
      // We don't currently support auto height for cloned widgets (Grid Widget Children)
      const isClone = (this.props as Partial<CopyInfo>).isClone;
      if (!isClone) {
        update(this.props.widgetId, height);
      }
    } else {
      console.warn("Update auto height not implemented");
    }
  }

  updateAutoWidth(width: number): void {
    const update = this.context.updateWidgetAutoWidth;
    if (update) {
      update(this.props.widgetId, width);
    } else {
      console.warn("Update auto width not implemented");
    }
  }

  abstract getPageView(): ReactNode;

  getCanvasView(): ReactNode {
    return this.getPageView();
  }

  hasInvalidProps() {
    return hasInvalidPropsInComponent(this.props.invalidProps);
  }

  getErrorMessage() {
    return getComponentErrorMessage(this.props.invalidProps);
  }

  // TODO(abhinav): Maybe make this a pure component to bailout from updating altogether.
  // This would involve making all widgets which have "states" to not have states,
  // as they're extending this one.
  shouldComponentUpdate(nextProps: WidgetProps, nextState: WidgetState) {
    if (
      shallowEqual(nextProps, this.props) &&
      shallowEqual(nextState, this.state)
    ) {
      return false;
    }
    return true;
  }

  // TODO(abhinav): These defaultProps seem unneccessary. Check it out.
  static defaultProps: Partial<WidgetProps> | undefined = {
    parentRowSpace: 1,
    parentColumnSpace: 1,
    top: Dimension.gridUnit(0),
    left: Dimension.gridUnit(0),
    dragDisabled: false,
    dropDisabled: false,
    isDeletable: true,
    resizeDisabled: false,
    disablePropertyPane: false,
    animateLoading: true,
  };
}

export default BaseWidget;

export type {
  WidgetProps,
  WidgetState,
  DynamicLayoutProps,
  DynamicVisibilityProperties,
  CopiedWidgets,
  PartialWidgetProps,
  WidgetPropsRuntime,
  NestedTextStylePropertyNames,
  WidgetPositionProps,
};
