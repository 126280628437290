import { Spinner } from "@blueprintjs/core";
import { Dimension } from "@superblocksteam/shared";
import React from "react";
import styled from "styled-components";
import {
  GridDefaults,
  PAGE_WIDGET_ID,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";

import BaseWidget, { WidgetPropsRuntime, WidgetState } from "./BaseWidget";

const SpinnerContainer = styled.div<{ appMode: APP_MODE }>`
  display: flex;
  min-height: calc(
    ${(props) => (props.appMode === APP_MODE.EDIT ? 50 : 100)}vh -
      ${(props) => props.theme.legacy.headerHeight}
  );
  justify-content: center;
  align-items: center;
`;

const SkeletonWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const PageLevelWidgets = [
  WidgetTypes.SECTION_WIDGET,
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
] as string[];

class SkeletonWidget extends BaseWidget<SkeletonWidgetProps, WidgetState> {
  getPageView() {
    if (this.props.widgetId === PAGE_WIDGET_ID) {
      return (
        <SpinnerContainer appMode={this.props.appMode} data-test="skeleton">
          <Spinner />
        </SpinnerContainer>
      );
    } else {
      let style = undefined;
      if (PageLevelWidgets.includes(this.props.loadedType)) {
        style = {
          height:
            this.props.loadedType === WidgetTypes.SECTION_WIDGET
              ? `${
                  Dimension.toPx(
                    this.props.height ?? Dimension.gridUnit(12),
                    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
                  ).value
                }px`
              : "0px",
        };
      }
      return (
        <SkeletonWrapper
          className="bp5-skeleton"
          data-test="skeleton"
          style={style}
        />
      );
    }
  }

  getWidgetType(): WidgetType {
    return "SKELETON_WIDGET";
  }
}

export interface SkeletonWidgetProps extends WidgetPropsRuntime {
  isLoading: boolean;
  loadedType: WidgetType;
}

export default SkeletonWidget;
