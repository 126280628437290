import React from "react";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { TextAlign, WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { EMPTY_RADIUS } from "legacy/themes/constants";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { iconPositionProperty, iconProperty } from "../appearanceProperties";
import {
  isDisabledProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import {
  borderProperty,
  borderRadiusProperty,
  textStyleCombinedProperty,
} from "../styleProperties";
import withMeta from "../withMeta";
import { ButtonComponentWithLayoutManaged } from "./ButtonComponent";
import {
  DEFAULT_BUTTON_BORDER_OBJECT,
  DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
  THEME_VALUE_BUTTON_BORDER_OBJECT,
  BUTTON_STYLE_OPTIONS,
} from "./constants";
import { ButtonWidgetProps } from "./types";
import { buttonColorStyles } from "./utils";

class ButtonWidget extends BaseWidget<ButtonWidgetProps, ButtonWidgetState> {
  onButtonClickBound: (event: React.MouseEvent<HTMLElement>) => void;
  constructor(props: ButtonWidgetProps) {
    super(props);
    this.onButtonClickBound = this.onButtonClick.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "text",
            label: "Label",
            helpText: "Sets the label of the button",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Submit",
          },
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
        heightSupportsFitContent: true,
        hidePadding: false,
      }),
      {
        sectionName: "Style",
        sectionCategory: PropsPanelCategory.Appearance,
        children: [
          {
            propertyName: "buttonStyle",
            label: "Button style",
            controlType: "DROP_DOWN",
            helpText: "Changes the style of the button",
            options: BUTTON_STYLE_OPTIONS,
            defaultValue: "PRIMARY_BUTTON",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          iconProperty(),
          iconPositionProperty(),
        ],
      },
      {
        sectionName: "Advanced",
        children: [
          textStyleCombinedProperty({
            label: "Label text style",
            textStyleParentDottedPath: "textProps",
            defaultValueFn: {
              variant: () => DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
            },
            additionalUserSelectableVariants: ["buttonLabel"],
            enableTextColorConfig: false,
          }),
          {
            propertyName: "textAlignment",
            propertyCategory: PropsPanelCategory.Appearance,
            helpText: "The horizontal alignment of the button label",
            label: "Label alignment",
            controlType: "RADIO_BUTTON_GROUP",
            defaultValue: "CENTER",
            hidden: (props: ButtonWidgetProps) => {
              return props.width.mode === "fitContent";
            },
            options: [
              {
                icon: "LEFT_ALIGN",
                value: TextAlign.LEFT,
              },
              {
                icon: "CENTER_ALIGN",
                value: TextAlign.CENTER,
              },
              {
                icon: "RIGHT_ALIGN",
                value: TextAlign.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          ...buttonColorStyles(),

          borderProperty({
            defaultValue: DEFAULT_BUTTON_BORDER_OBJECT,
            themeValue: () => {
              return {
                value: THEME_VALUE_BUTTON_BORDER_OBJECT,
                treatAsNull: true,
              };
            },
            hidden: (props: ButtonWidgetProps) => {
              return props.buttonStyle !== "PRIMARY_BUTTON";
            },
          }),
          borderRadiusProperty({
            defaultValue: EMPTY_RADIUS,
            themeValue: ({ theme }) => {
              return {
                treatAsNull: false,
                value: createPerCornerBorderRadius(theme.borderRadius),
              };
            },
            hidden: (props: ButtonWidgetProps) => {
              return props.buttonStyle === "TERTIARY_BUTTON";
            },
          }),
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyCategory: PropsPanelCategory.Appearance,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          ...visibleProperties({ useJsExpr: false }),
          isDisabledProperty({ useJsExpr: false }),
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onClick",
            "Triggers an action when the button is clicked",
          ),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      text: VALIDATION_TYPES.TEXT,
      buttonStyle: VALIDATION_TYPES.TEXT,
      icon: VALIDATION_TYPES.ICONS,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  onButtonClick(e: React.MouseEvent<HTMLElement>) {
    // If the button is inside a grid or other container, this prevents
    // the container's onClick from firing
    e.stopPropagation();
    if (this.props.onClick && !this.state.isLoading) {
      this.setState({
        isLoading: true,
      });
      const callbackId = addNewPromise(this.handleActionComplete);
      super.runEventHandlers({
        steps: this.props.onClick,
        type: EventType.ON_CLICK,
        callbackId,
      });
    }
  }

  handleActionComplete = () => {
    // TODO: Prevent state update after unmount
    this.setState({
      isLoading: false,
    });
  };

  getPageView() {
    return (
      <ButtonComponentWithLayoutManaged
        {...this.props}
        isLoading={this.props.isLoading || this.state.isLoading}
        onClick={this.onButtonClickBound}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "BUTTON_WIDGET";
  }
}

interface ButtonWidgetState extends WidgetState {
  isLoading: boolean;
}

export default ButtonWidget;
export const ConnectedButtonWidget = withMeta(ButtonWidget);
