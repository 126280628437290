import { Classes as BlueprintClasses, Classes } from "@blueprintjs/core";
import { Datetime2Classes as DatepickerClasses } from "@blueprintjs/datetime2";
import { css } from "styled-components";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import {
  styleFromStyleBlock,
  disabledStyles,
  inputStyleFromStyleBlock,
  selectStyleFromStyleBlock,
  hoverStyles,
  activeStyles,
} from "./utils";

/*
These styles are added to the styles from the DatePicker widget which are used for
both the properties panel and iframe styling
*/
export const generateDatepickerStyles = (generatedTheme: GeneratedTheme) => {
  return css`
    .${Classes.BUTTON}:hover {
      background: ${generatedTheme.colors.appBackground};
      opacity: 0.7;
    }

    .${CLASS_NAMES.PRIMARY_COLOR_ICON} {
      color: ${generatedTheme.colors.primary500};
    }

    .${DatepickerClasses.DATE_INPUT_POPOVER} {
      .${Classes.POPOVER_CONTENT} {
        padding: 0;
      }
    }

    .bp5-datepicker {
      background: ${generatedTheme.colors.neutral};
      padding: 4px;
      --rdp-cell-size: 28px; /* for react-date-picker */

      box-shadow:
        0px 1px 3px rgba(34, 39, 47, 0.06),
        0px 12px 32px -8px rgba(34, 39, 47, 0.16),
        0px 0px 1px rgba(34, 39, 47, 0.16);
      border-radius: 4px;
      font-size: 12px;
      .${Classes.DIVIDER} {
        display: none;
      }
      .${DatepickerClasses.DATEPICKER3_DROPDOWN_MONTH} {
        margin-right: 8px;
      }
      .${DatepickerClasses.DATEPICKER3_DROPDOWN_YEAR},
        .${DatepickerClasses.DATEPICKER3_DROPDOWN_MONTH} {
        ${selectStyleFromStyleBlock(
          generatedTheme.dropdown.select,
          generatedTheme.dropdown.caratIcon,
        )};
      }

      .rdp-tfoot {
        .${Classes.BUTTON}:not(:last-child) {
          margin-right: 8px;
        }
        .${BlueprintClasses.BUTTON} {
          ${styleFromStyleBlock(generatedTheme.buttons.secondaryNeutral)}
        }
      }
      &.show-timepicker {
        .${DatepickerClasses.DATEPICKER_FOOTER} {
          justify-content: end;
        }
      }

      .${DatepickerClasses.DATEPICKER_TIMEPICKER_WRAPPER} {
        position: relative;
        .${DatepickerClasses.TIMEPICKER} {
          margin: 0px;
          .${DatepickerClasses.TIMEPICKER_AMPM_SELECT} {
            width: 48px;
            ${selectStyleFromStyleBlock(
              generatedTheme.dropdown.select,
              generatedTheme.dropdown.caratIcon,
            )};
          }
          .${DatepickerClasses.TIMEPICKER_INPUT_ROW} {
            ${inputStyleFromStyleBlock(generatedTheme.inputs.input)}
            padding: 0 4px;
            box-shadow: none;
            height: 34px;
            input {
              position: relative;
              top: -2px;
              padding-top: 3px;
              width: 22px;
              color: inherit;
              top: 0px;
              box-shadow: none;
              &:active,
              &:focus {
                box-shadow: none;
              }
            }
            span {
              width: 6px;
            }
          }
        }
      }

      .${DatepickerClasses.DATEPICKER3_DROPDOWN_MONTH} {
        border-collapse: separate;
      }

      .${DatepickerClasses.DATEPICKER3_DAY} {
        border-radius: 50%;
        ${styleFromStyleBlock(generatedTheme.datepicker.cell)}
        border: 1px solid transparent;
        &:not(.${DatepickerClasses.DATEPICKER3_DAY_SELECTED}):not(
            .${DatepickerClasses.DATEPICKER3_DAY_DISABLED}
          ):hover {
          border-radius: 50%;
          ${hoverStyles(generatedTheme.datepicker.cell)}
        }
        &.${DatepickerClasses.DATEPICKER3_DAY_OUTSIDE} {
          ${disabledStyles(generatedTheme.datepicker.cell)}
        }
        &.${DatepickerClasses.DATEPICKER3_DAY_SELECTED} {
          ${activeStyles(generatedTheme.datepicker.cell)}
          box-shadow: 0px 1px 3px rgba(39, 187, 255, 0.24);
        }
      }
    }
  `.join("");
};
