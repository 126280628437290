import { CustomComponentProperty } from "@superblocksteam/shared";
import {
  VALIDATION_TYPES,
  ValidationType,
} from "legacy/constants/WidgetValidation";
import { DataTreeEntity } from "legacy/entities/DataTree/dataTreeFactory";
import { VALIDATORS } from "legacy/workers/validators/validations";

export function getCCPropertyValidationType(
  dataType: CustomComponentProperty["dataType"],
): ValidationType {
  switch (dataType) {
    case "number":
      return VALIDATION_TYPES.NUMBER_ALLOW_UNDEFINED;
    case "boolean":
      return VALIDATION_TYPES.BOOLEAN;
    case "any":
      return VALIDATION_TYPES.ANY;
    default:
      return VALIDATION_TYPES.TEXT;
  }
}

export function validateCCPropertyValue(
  dataType: CustomComponentProperty["dataType"],
  value: unknown,
  entity: DataTreeEntity,
) {
  const validationType = getCCPropertyValidationType(dataType);
  const validator = VALIDATORS[validationType];
  if (validator) {
    const { parsed, isValid, message } = validator(value, entity);
    if (isValid) {
      return parsed;
    } else {
      throw new TypeError(message ?? "Value does not match the type");
    }
  }
  return null;
}
