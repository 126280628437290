import {
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  Typographies,
} from "@superblocksteam/shared";
import { css } from "styled-components";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import {
  getCustomTypographyClassname,
  getTextCssClassFromVariant,
} from "../utils";
import { styleFromStyleBlock, styleFromTextBlockWithColors } from "./utils";

export const generateTextStyles = (
  textStyles: Typographies,
  builtinTypographies: GeneratedTheme["builtinTypographies"],
  fallbackFont: string,
) => {
  return css`
    ${styleFromTextBlockWithColors(textStyles.body2)};
    ${Object.keys(textStyles).reduce((styles: string, key) => {
      if (key === CUSTOM_THEME_TYPOGRAPHY_KEY) {
        textStyles.custom &&
          Object.keys(textStyles.custom).forEach((typographyKey) => {
            if (textStyles?.custom?.[typographyKey]?.styles) {
              styles += `
            .${getCustomTypographyClassname(typographyKey)} {
              ${styleFromTextBlockWithColors(
                textStyles?.custom?.[typographyKey]?.styles,
                fallbackFont,
              )};
            }
          `;
            }
          });
        return styles;
      } else {
        styles += `
          .${getTextCssClassFromVariant(key)} {
            ${styleFromTextBlockWithColors(
              textStyles[
                key as keyof Omit<
                  Typographies,
                  typeof CUSTOM_THEME_TYPOGRAPHY_KEY
                >
              ],
              fallbackFont,
            )};
          }
        `;
        return styles;
      }
    }, "")}

    a {
      ${styleFromTextBlockWithColors(textStyles.link)}
    }
    .${CLASS_NAMES.LINK} {
      ${styleFromTextBlockWithColors(textStyles.link)}
    }

    .${CLASS_NAMES.INPUT} {
      input::placeholder,
      textarea::placeholder {
        ${styleFromTextBlockWithColors(textStyles.inputPlaceholder)}
      }
    }
    textarea.${CLASS_NAMES.INPUT}::placeholder {
      ${styleFromTextBlockWithColors(textStyles.inputPlaceholder)}
    }

    // builtins
    .${CLASS_NAMES.SYSTEM_TEXT} {
      ${styleFromStyleBlock(builtinTypographies.system)}
    }
    .${CLASS_NAMES.BUILTIN_BODY} {
      ${styleFromTextBlockWithColors(builtinTypographies.body)}
    }
    .${CLASS_NAMES.ELLIPSIS_TEXT} {
      overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
      text-overflow: ellipsis;
      height: auto !important; // undoes antd styles on labels
    }
  `.join("");
};
