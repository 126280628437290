import { css } from "styled-components";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import { generateBorderStyle, styleFromDimension } from "./utils";

export const generatePaginatorStyles = (theme: GeneratedTheme) => {
  return css`
    .${CLASS_NAMES.PAGINATOR} {
      border-color: ${theme.colors.neutral100};
      .rc-pagination-item {
        font-family: ${theme.typographies.body2.fontFamily};
        background: ${theme.colors.neutral};
        &:hover {
          background: ${theme.colors.primaryHighlight};
          border-color: ${theme.colors.primary500};
        }
      }
      .rc-pagination-item a {
        color: ${theme.colors.neutral300};
      }
      .rc-pagination-item:focus a,
      .rc-pagination-item:hover a {
        color: ${theme.colors.primary500};
      }
      .rc-pagination-item-active {
        font-weight: 500;
        background: ${theme.colors.primary500};
        border-color: ${theme.colors.primary500};
      }
      .rc-pagination-item-active a {
        color: ${theme.colors.contrastText};
      }
      .rc-pagination-item-active:focus,
      .rc-pagination-item-active:hover {
        background: ${theme.colors.primary600};
        border-color: ${theme.colors.primary600};
      }
      .rc-pagination-item-active:focus a,
      .rc-pagination-item-active:hover a {
        color: ${theme.colors.contrastText};
      }
      .rc-pagination-jump-prev button,
      .rc-pagination-jump-next button {
        background: ${theme.colors.neutral};
        color: ${theme.colors.neutral300};
        &:hover {
          background: ${theme.colors.primaryHighlight};
          color: ${theme.colors.primary500};
          border-color: ${theme.colors.primary500};
        }
      }
      .rc-pagination-prev,
      .rc-pagination-next {
        background: ${theme.colors.neutral};
        &:hover {
          background: ${theme.colors.primaryHighlight};
          border-color: ${theme.colors.primary500};
        }
      }

      .rc-pagination-prev button,
      .rc-pagination-next button {
        color: ${theme.colors.neutral300};
      }

      .rc-pagination-prev:focus .rc-pagination-item-link,
      .rc-pagination-next:focus .rc-pagination-item-link,
      .rc-pagination-prev:hover .rc-pagination-item-link,
      .rc-pagination-next:hover .rc-pagination-item-link {
        color: ${theme.colors.primary500};
      }

      .rc-pagination-disabled.rc-pagination-prev,
      .rc-pagination-disabled.rc-pagination-next {
        background: ${theme.colors.neutral};
        border-color: transparent;
      }

      .rc-pagination-disabled .rc-pagination-item-link,
      .rc-pagination-disabled:hover .rc-pagination-item-link,
      .rc-pagination-disabled:focus .rc-pagination-item-link {
        color: ${theme.colors.neutral200};
      }
      .rc-pagination-simple .rc-pagination-simple-pager input {
        background-color: ${theme.inputs.input.backgroundColor.default};
        border: ${generateBorderStyle(theme.inputs.input)};
        border-radius: ${styleFromDimension(theme.inputs.input.borderRadius)};
      }
      .rc-pagination-simple .rc-pagination-simple-pager input:hover {
        border-color: ${theme.inputs.input.borderColor.hover};
      }
      .rc-pagination.rc-pagination-disabled .rc-pagination-item {
        background: ${theme.colors.neutral50};
        border-color: ${theme.colors.neutral100};
        cursor: not-allowed;
      }
      .rc-pagination.rc-pagination-disabled .rc-pagination-item a {
        color: ${theme.colors.neutral200};
      }
      .rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
        background: ${theme.colors.neutral400};
      }
      .rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
        color: ${theme.colors.neutral};
      }
      .rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
        color: ${theme.colors.neutral200};
      }
    }
  `.join("");
};
