import { Classes as BlueprintClasses } from "@blueprintjs/core";
import { css } from "styled-components";
import { generatePaddingCss } from "legacy/widgets/base/generatePaddingStyle";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import { generateBorderStyle, styleFromDimension } from "./utils";

export const generatePopperStyles = (
  popperStyles: GeneratedTheme["popover"],
) => {
  const styles = css`
    border: ${generateBorderStyle(popperStyles)};
    background: ${popperStyles.backgroundColor.default};
    color: ${popperStyles.textColor.default};
    box-shadow: ${popperStyles.boxShadow.default};
    border-radius: ${styleFromDimension(popperStyles.borderRadius)};
  `;

  return css`
    .${BlueprintClasses.POPOVER}, .${CLASS_NAMES.POPOVER_WRAPPER} {
      ${styles}
      .${BlueprintClasses.POPOVER_CONTENT} {
        overflow: hidden;
        ${generatePaddingCss(popperStyles.padding)};
      }
      .${BlueprintClasses.MENU} {
        max-width: 100%;
        max-height: auto;
      }
    }

    .ant-popover-content {
      .ant-popover-arrow-content {
        background: ${popperStyles.backgroundColor.default};
      }
      .ant-popover-inner {
        ${styles}
      }
    }

    .ant-select-dropdown {
      ${styles}
    }
  `.join("");
};
