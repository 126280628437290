import React from "react";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import BaseWidget, { WidgetState } from "../BaseWidget";
import withMeta from "../withMeta";
import { LinkComponentWithLayoutManaged } from "./LinkComponent";
import LinkPropertyPaneConfig from "./LinkPropertyPaneConfig";
import { LinkWidgetProps } from "./types";

class LinkWidget extends BaseWidget<LinkWidgetProps, LinkWidgetState> {
  onLinkClickBound: (event: React.MouseEvent<HTMLElement>) => void;

  constructor(props: LinkWidgetProps) {
    super(props);
    this.onLinkClickBound = this.onLinkClick.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return LinkPropertyPaneConfig;
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      text: VALIDATION_TYPES.TEXT,
      linkStyle: VALIDATION_TYPES.TEXT,
      icon: VALIDATION_TYPES.ICONS,
      url: VALIDATION_TYPES.URL_FOR_HYPERLINK,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  onLinkClick(e: React.MouseEvent<HTMLElement>) {
    // If the link is inside a grid or other container, this prevents
    // the container's onClick from firing
    e.stopPropagation();
  }

  getPageView() {
    return (
      <LinkComponentWithLayoutManaged
        {...this.props}
        onLinkClick={this.onLinkClickBound}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "LINK_WIDGET";
  }
}

type LinkWidgetState = WidgetState;

export default LinkWidget;
export const ConnectedLinkWidget = withMeta(LinkWidget);
