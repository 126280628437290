import React from "react";

import { WidgetType } from "legacy/constants/WidgetConstants";

import { WidgetPropertyValidationType } from "legacy/constants/WidgetValidation";

import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import withMeta, { WithMeta } from "../withMeta";
import { CustomComponent } from "./CustomComponent";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";

export class CustomWidget extends BaseWidget<CustomWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig<CustomWidgetProps>[] {
    throw new Error("Custom components do not statically define property pane");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      // ...BASE_WIDGET_VALIDATION,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getDerivedPropertiesMap(): Record<keyof CustomWidgetProps, string> {
    throw new Error("Custom components do not statically define derived props");
  }

  static getMetaPropertiesMap(): CustomWidgetProps {
    throw new Error("Custom components do not statically define meta props");
  }

  // create a bound version of runEventHandlers so that it can be passed down to `CustomComponent`
  // also, the value is stable so it is suitabled to be used in dependency arrays
  runEventHandlersBound = this.runEventHandlers.bind(this);

  getPageView() {
    return (
      <CustomComponent
        type={this.props.type}
        runEventHandlers={this.runEventHandlersBound}
        widgetId={this.props.widgetId}
        properties={this.props}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "CUSTOM_WIDGET";
  }
}

export interface CustomWidgetProps extends WidgetPropsRuntime, WithMeta {}

export default withMeta(CustomWidget) as unknown as typeof CustomWidget;
