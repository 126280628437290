import { Dimension } from "@superblocksteam/shared";
import {
  get,
  set,
  isNumber,
  range,
  isEmpty,
  toString,
  xor,
  isObject,
  isEqual,
} from "lodash";
import React from "react";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { WidgetTypes, GridDefaults } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import { APP_MODE } from "legacy/reducers/types";
import { TextStyleWithVariant } from "legacy/themes";
import { NO_BORDER_OBJECT } from "legacy/themes/constants";
import { VALIDATORS } from "legacy/workers/validators/validations";
import { fastClone } from "utils/clone";
import { getComponentDimensions } from "utils/size";
import ContainerWidget from "../ContainerWidget";
import WidgetFactory, {
  DerivedPropertiesMap,
  WidgetActionHook,
} from "../Factory";
import { isFitContent } from "../base/sizing";
import { USE_DEFAULT_COMPUTE_HEIGHT } from "../withComputedHeight";
import withMeta, { WithMeta } from "../withMeta";
import { CopyInfo } from "../withWidgetProps";
import GridComponent, {
  GridHeader,
  GridComponentEmpty,
  GridComponentLoading,
} from "./GridComponent";
import GridPagination from "./GridPagination";
import GridWidgetPropertyCategories from "./GridWidgetPropertyCategories";
import derivedProperties from "./derived";
import { actionHooks } from "./hooks";
import type { WidgetProps, WidgetPropsRuntime } from "../BaseWidget";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import type { WidgetType } from "legacy/constants/WidgetConstants";
import type { WidgetPropertyValidationType } from "legacy/constants/WidgetValidation";

function applyRecursively(
  widget: WidgetPropsRuntime,
  fn: (widget: WidgetPropsRuntime) => unknown,
) {
  fn(widget);
  widget.children?.forEach((child: WidgetPropsRuntime) => {
    applyRecursively(child, fn);
  });
}

const GRID_PAGINATION_HEIGHT = 28;
const GRID_TITLE_HEIGHT = 58;
const GRID_GAP = 0;

const disallowedChildren = new Set([
  WidgetTypes.GRID_WIDGET,
  WidgetTypes.INPUT_WIDGET,
  WidgetTypes.TABLE_WIDGET,
  WidgetTypes.CHART_WIDGET,
  WidgetTypes.CHECKBOX_WIDGET,
  WidgetTypes.SWITCH_WIDGET,
  WidgetTypes.DROP_DOWN_WIDGET,
  WidgetTypes.FORM_WIDGET,
  WidgetTypes.DATE_PICKER_WIDGET,
  WidgetTypes.RADIO_GROUP_WIDGET,
  WidgetTypes.CONTAINER_WIDGET,
  WidgetTypes.CODE_WIDGET,
  WidgetTypes.TABS_WIDGET,
  WidgetTypes.CANVAS_WIDGET,
  WidgetTypes.SECTION_WIDGET,
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.PAGE_WIDGET,
  WidgetTypes.RICH_TEXT_EDITOR_WIDGET,
  WidgetTypes.FILE_PICKER_WIDGET,
]);

const getVisibilityFlags = (
  data: Array<Record<string, unknown>> | undefined,
  filter: string | undefined,
) => {
  if (isEmpty(filter)) {
    return new Array(data?.length ?? 0).fill(true);
  }
  if (!data) {
    return [];
  }
  return data.map((d) => {
    if (isObject(d)) {
      // Same logic as table, although table also supports more filtering
      return Object.values(d)
        .join(", ")
        .toUpperCase()
        .includes((filter as string).toUpperCase());
    }
    return false;
  });
};

class GridWidget extends ContainerWidget<GridWidgetProps<WidgetPropsRuntime>> {
  state = {
    page: 1,
    searchText: undefined,
  };
  widgetConfigMap = WidgetFactory.getWidgetTypeConfigMap();
  headerRef: React.RefObject<HTMLDivElement> = React.createRef();
  /**
   * returns the property pane config of the widget
   */
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<GridWidgetProps>[]
    | undefined {
    return GridWidgetPropertyCategories;
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      gridData: VALIDATION_TYPES.TABLE_DATA,
      defaultSelectedCell: VALIDATION_TYPES.DEFAULT_SELECTED_ROW,
      title: VALIDATION_TYPES.TEXT,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      selectedCell: `{{(${derivedProperties.getSelectedCell})()}}`,
      selectedCellSchema: `{{(${derivedProperties.getSelectedCellSchema})()}}`,
      cells: `{{(${derivedProperties.getCells})()}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedCellIndex: "defaultSelectedCell",
    };
  }

  static getMetaPropertiesMap(): Record<string, unknown> {
    return {
      selectedCellIndex: undefined,
      searchText: undefined,
    };
  }

  // Use this function for computeMinHeightFromProps if you want to use the default
  static computeMinHeightFromProps = USE_DEFAULT_COMPUTE_HEIGHT;

  static readonly allowedChildTypes: WidgetType[] = Object.values(
    WidgetTypes,
  ).filter((val) => !disallowedChildren.has(val));

  static applyActionHook: WidgetActionHook = actionHooks;

  getCurrentRowStructure = (gridData?: Array<Record<string, unknown>>) => {
    return Array.isArray(gridData) && gridData.length > 0
      ? Object.assign(
          {},
          ...Object.keys(gridData[0]).map((key) => ({
            [key]: "",
          })),
        )
      : {};
  };

  componentDidMount() {
    if (!this.props.childAutoComplete) {
      const rowStructure = this.getCurrentRowStructure(this.props.gridData);

      this.props.updateWidgetMetaProperty("childAutoComplete", {
        currentCell: rowStructure,
      });
    }
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps: GridWidgetProps<WidgetPropsRuntime>) {
    const oldRowStructure = this.getCurrentRowStructure(prevProps.gridData);
    const newRowStructure = this.getCurrentRowStructure(this.props.gridData);

    if (
      xor(Object.keys(oldRowStructure), Object.keys(newRowStructure)).length > 0
    ) {
      this.props.updateWidgetMetaProperty("childAutoComplete", {
        currentCell: newRowStructure,
      });
    }
  }

  getSelectedCellIndex = () => {
    return isNumber(this.props.selectedCellIndex)
      ? this.props.selectedCellIndex
      : isNumber(this.props.defaultSelectedCell)
        ? this.props.defaultSelectedCell
        : -1;
  };

  selectCellHandler = (rowIndex: number) => {
    const selectedCellIndex = this.getSelectedCellIndex();

    // This logic was previously a toggle, now it's a latch- once the user selects
    // the first cell there is always a selection (unless they learn about resetComponent)
    if (selectedCellIndex !== rowIndex) {
      this.props.updateWidgetMetaProperty("selectedCellIndex", rowIndex);
    }
  };

  /**
   * on click item action
   *
   * @param rowIndex
   * @param action
   * @param onComplete
   */
  clickHandler = (rowIndex: number, action: MultiStepDef | undefined) => {
    if (!action) return;

    super.runEventHandlers({
      steps: action,
      type: EventType.ON_CELL_SELECTED,
      additionalNamedArguments: {
        currentCell: this.props.gridData?.[rowIndex],
      },
    });
  };

  getChildElements = () => {
    const numberOfItemsInGrid = this.props.gridData?.length ?? 0;
    if (this.props.children && this.props.children.length > 0) {
      const elements: Array<WidgetPropsRuntime | null> = new Array(
        numberOfItemsInGrid,
      ).fill(null);
      const childCanvas = this.props.children[0] as WidgetPropsRuntime;
      try {
        // here we are duplicating the template for each items in the data array
        for (let i = 0; i < numberOfItemsInGrid; i++) {
          elements[i] = this.extractBindingsFromChildren(
            fastClone(childCanvas),
            i,
          );
        }
      } catch (e) {
        console.error(e);
        return;
      }

      return elements
        .map((childWidgetData, index) => {
          if (!childWidgetData) return null;

          // Additions to the child widget data
          type cloneProps = Partial<CopyInfo> & {
            onClick?: any;
            onClickCapture?: any;
            selected?: boolean;
            focused?: boolean;
          };
          const copy: WidgetPropsRuntime & cloneProps =
            fastClone(childWidgetData);
          const newId = `list-widget-child-id-${index}-${copy.widgetName}`;
          if (this.props.appMode === APP_MODE.PUBLISHED) {
            // The widgetIds of all the children need to be updated because otherwise
            // we get duplication of the focus states
            copy.originalId = copy.widgetId;
            copy.widgetId = newId;
            if (copy.children) {
              set(copy.children, `0.originalId`, copy.children[0].widgetId);
              set(copy.children, `0.parentId`, newId);
              set(copy.children, `0.widgetId`, `${newId}-0`);
            }
          }

          // We need to do this recursively for all the children
          applyRecursively(copy, (child: WidgetPropsRuntime & cloneProps) => {
            child.isClone = true;
          });

          set(copy, `children.0.disabledResizeHandles`, [
            "left",
            "top",
            "right",
            "topLeft",
            "topRight",
            "bottomRight",
            "bottomLeft",
          ]);
          set(copy, "children.0.ignoreCollision", true);
          set(copy, "shouldScrollContents", undefined);
          set(copy, "padding", {}); // no padding on canvas
          set(copy, "padding", "none"); // no padding on canvas
          set(copy, "children.0.padding", {}); // no padding on container
          set(copy, "children.0.border", NO_BORDER_OBJECT); // no border on container

          const rowHeight = get(
            elements,
            "0.children.0.height",
          ) as Dimension<"gridUnit">;
          copy.top = Dimension.gridUnit(0);
          copy.height = rowHeight;
          copy.minHeight = rowHeight; // TODO: This should be pixels

          copy.parentId = this.props.widgetId;
          set(
            copy,
            "children.0.resizeDisabled",
            index > 0 && this.props.appMode === APP_MODE.EDIT,
          );
          set(copy, "children.0.backgroundColor", "transparent");

          copy.onClickCapture = () => {
            this.selectCellHandler(index);
          };
          // Only fires if the event is allowed to bubble up. Bubbling is prevented
          // when clicking on an inner component in Edit mode
          copy.onClick = () => {
            this.clickHandler(index, this.props.onCellClicked);
          };
          copy.selected = this.getSelectedCellIndex() === index;
          copy.focused = index === 0 && this.props.appMode === APP_MODE.EDIT;

          copy.canExtend = undefined;
          copy.isVisible = this.props.isVisible;

          const defaultProperties = WidgetFactory.getWidgetDefaultPropertiesMap(
            copy.type,
          );
          const defaultMetaProps = WidgetFactory.getWidgetMetaPropertiesMap(
            copy.type,
          );

          // Update the display name for the children to be unique
          get(copy, "children.0.children.0.children", []).forEach(
            (child: WidgetProps) => {
              set(
                child,
                "widgetName",
                `${this.props.widgetName}.cells[${index}].${child.widgetName}`,
              );
            },
          );

          return WidgetFactory.createWidget(
            {
              ...defaultProperties,
              ...defaultMetaProps,
              ...copy,
            },
            this.props.appMode,
          );
        })
        .filter((el) => Boolean(el));
    }
  };

  parseEvaluatedValue = (
    widgetType: string,
    propertyName: string,
    value: any,
    entity: WidgetProps,
  ) => {
    // get the validation for this property
    const validationType =
      this.widgetConfigMap[widgetType].validations?.[propertyName];
    if (
      validationType &&
      typeof validationType === "string" &&
      VALIDATORS[validationType]
    ) {
      const { parsed } = VALIDATORS[validationType](
        value,
        entity as DataTreeWidget,
      );
      return parsed;
    }

    return toString(value);
  };

  // This function happens on every render, but should probably happen in the evaluator
  extractBindingsFromChildren = (
    widget: WidgetPropsRuntime,
    cellIndex: number,
  ) => {
    const { dynamicBindingPathList, gridBindings } = this.props;

    applyRecursively(widget, (child: WidgetPropsRuntime) => {
      dynamicBindingPathList?.forEach(({ key }) => {
        if (!key.startsWith(`gridBindings.${child.widgetName}.`)) {
          return;
        }

        const evaluatedProperty = get(
          gridBindings,
          key.replace(`gridBindings.`, ""),
        );

        if (
          Array.isArray(evaluatedProperty) &&
          evaluatedProperty.length > cellIndex
        ) {
          const evaluatedValue = evaluatedProperty[cellIndex];
          const propertyName = key.replace(
            `gridBindings.${child.widgetName}.`,
            "",
          );
          set(
            child,
            propertyName,
            this.parseEvaluatedValue(
              child.type,
              propertyName,
              evaluatedValue,
              child,
            ),
          );
        }
      });
    });

    return widget;
  };

  static enhancements = {
    child: {
      autocomplete: (parentProps: any) => {
        return {
          "!doc": "",
          "!type": "string",
          ...parentProps.childAutoComplete,
        };
      },
    },
  };

  handleSearch = (text: string) => {
    // TODO: Go back to initial value, it's not always undefined
    this.props.updateWidgetMetaProperty("selectedCellIndex", undefined);
    this.setState((prevState) => ({ ...prevState, page: 1, searchText: text }));
  };

  shouldPaginate = () => {
    const { children, gridData, columnCount = 1 } = this.props;
    const visibleItems = getVisibilityFlags(
      gridData,
      this.state.searchText,
    ).filter(Boolean);
    if (!visibleItems?.length) {
      return { shouldPaginate: false, perPage: 0 };
    }

    const isAutoHeight = isFitContent(this.props.height.mode);
    const isUnboundedAutoHeight = isAutoHeight && this.props.maxHeight == null;

    const totalRows = Math.ceil(visibleItems.length / columnCount);

    const templateRows = get(
      children,
      "0.children.0.height",
    ) as Dimension<"gridUnit">;
    const templateHeight =
      templateRows.value * GridDefaults.DEFAULT_GRID_ROW_HEIGHT;
    const spaceTakenByOneContainer =
      templateHeight + (GRID_GAP * (totalRows - 1)) / totalRows;
    const measuredHeaderHeight = this.headerRef?.current
      ? this.headerRef.current.clientHeight
      : undefined;
    const headerHeight =
      this.props.isSearchable || !isEmpty(this.props.title)
        ? (measuredHeaderHeight ?? GRID_TITLE_HEIGHT)
        : 0;

    if (isUnboundedAutoHeight) {
      const calculatedHeight = isAutoHeight
        ? totalRows * spaceTakenByOneContainer + headerHeight
        : undefined;

      return {
        shouldPaginate: false,
        perPage: visibleItems.length,
        calculatedHeight,
      };
    }
    const componentHeight =
      isAutoHeight && this.props.maxHeight != null
        ? Dimension.toPx(
            this.props.maxHeight,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).value
        : getComponentDimensions(this.props).componentHeight;

    let totalSpaceAvailable =
      componentHeight - GRID_PAGINATION_HEIGHT - headerHeight;

    const shouldPaginate =
      templateHeight * totalRows + GRID_GAP * (totalRows - 1) >
      totalSpaceAvailable + GRID_PAGINATION_HEIGHT;

    if (!shouldPaginate) {
      // Use the extra space when we can fit everything without pagination
      totalSpaceAvailable += GRID_PAGINATION_HEIGHT;
    }

    const rowsPerPage = Math.floor(
      totalSpaceAvailable / spaceTakenByOneContainer,
    );
    const perPage = rowsPerPage * columnCount;
    const resultPerPage = isNaN(perPage) ? 0 : Math.floor(perPage);

    const calculatedHeight = isAutoHeight
      ? Math.min(rowsPerPage, totalRows) * spaceTakenByOneContainer +
        headerHeight +
        (shouldPaginate ? GRID_PAGINATION_HEIGHT : 0)
      : undefined;

    return {
      shouldPaginate,
      perPage: resultPerPage,
      calculatedHeight,
    };
  };

  /**
   * view that is rendered in editor
   */
  getPageView() {
    const children = this.getChildElements();
    const { componentHeight } = getComponentDimensions(this.props);
    const isUnboundedAutoHeight =
      isFitContent(this.props.height.mode) && this.props.maxHeight == null;

    const { perPage, shouldPaginate, calculatedHeight } = this.shouldPaginate();

    const templateRows = get(
      this.props.children,
      "0.children.0.height",
    ) as Dimension<"gridUnit">;
    const templateHeight =
      templateRows.value * GridDefaults.DEFAULT_GRID_ROW_HEIGHT;

    const visibilityFlags = getVisibilityFlags(
      this.props.gridData,
      this.state.searchText,
    );
    const visibleChildren =
      children?.filter((child, index) => visibilityFlags[index]) ?? [];

    if (this.props.isLoading) {
      return (
        <GridComponentLoading
          {...this.props}
          overrideHeight={calculatedHeight}
          header={
            <GridHeader
              key={`list-widget-search-${this.state.page}`}
              searchText={this.state.searchText}
              title={this.props.title}
              onSearch={this.handleSearch}
              isSearchable={Boolean(this.props.isSearchable)}
              headerProps={this.props.headerProps}
            />
          }
        >
          {range(10 * (this.props.columnCount ?? 1)).map((i) => (
            <div
              className="bp5-card bp5-skeleton"
              key={`skeleton-${i}`}
              style={{ height: templateHeight }}
            />
          ))}
        </GridComponentLoading>
      );
    }

    const hasNoData =
      (Array.isArray(this.props.gridData) &&
        this.props.gridData.filter((cell) => !isEmpty(cell)).length === 0) ||
      !children;
    if (hasNoData) {
      return (
        <GridComponentEmpty {...this.props}>
          No data to display
        </GridComponentEmpty>
      );
    }

    if (!isUnboundedAutoHeight) {
      if (
        isNaN(templateHeight) ||
        templateHeight > (calculatedHeight ?? componentHeight) - 45
      ) {
        return (
          <GridComponentEmpty {...this.props}>
            Please make sure the height of each grid cell is smaller than the
            overall height of the grid.
          </GridComponentEmpty>
        );
      }
    }

    const shouldRenderFooter =
      !isFitContent(this.props.height.mode) || shouldPaginate;

    return (
      <GridComponent
        {...this.props}
        overrideHeight={calculatedHeight}
        header={
          <GridHeader
            key={`list-widget-search-${this.state.page}`}
            searchText={this.state.searchText}
            title={this.props.title}
            onSearch={this.handleSearch}
            isSearchable={Boolean(this.props.isSearchable)}
            ref={this.headerRef}
            headerProps={this.props.headerProps}
          />
        }
        footer={
          shouldRenderFooter ? (
            <GridPagination
              current={this.state.page}
              disabled={false}
              onChange={(page: number) => {
                this.setState((prevState) => {
                  return { ...prevState, page };
                });
              }}
              perPage={perPage}
              total={this.props.gridData?.length ?? 0}
            />
          ) : undefined
        }
        hasPagination={shouldPaginate}
        key={`list-widget-page-${this.state.page}`}
      >
        {visibleChildren.slice(
          (this.state.page - 1) * perPage,
          (this.state.page - 1) * perPage + perPage,
        )}
        {visibleChildren.length === 0 && (
          <div
            style={{
              gridColumn: `1 / span ${this.props.columnCount}`,
              gridRow: `1 / span ${templateRows}`,
              height: templateHeight,
            }}
          >
            <GridComponentEmpty>No results</GridComponentEmpty>
          </div>
        )}
      </GridComponent>
    );
  }

  /**
   * returns type of the widget
   */
  getWidgetType(): WidgetType {
    return WidgetTypes.GRID_WIDGET;
  }
}

export interface GridWidgetProps<
  T extends WidgetPropsRuntime = WidgetPropsRuntime,
> extends WidgetPropsRuntime,
    WithMeta {
  children?: T[];
  shouldScrollContents?: boolean;
  onCellClicked?: MultiStepDef;
  gridData?: Array<Record<string, unknown>>;
  gridBindings?: any;
  childAutoComplete?: Record<string, Record<string, unknown>>;
  columnCount?: number;
  title?: string;
  defaultSelectedCell?: string;
  isSearchable?: boolean;
  selectedCellSchema?: Record<string, unknown>;
  selectedCellIndex?: number;
  cells: any[];
  gridCellScrollable?: boolean;
  headerProps?: {
    textStyle: TextStyleWithVariant;
  };
}

export default GridWidget;
export const ConnectedGridWidget = withMeta(GridWidget);
