import React from "react";
import { connect } from "react-redux";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { type AppState } from "store/types";

import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { isFitContent } from "../base/sizing";
import { isActionHandlerSet } from "../shared";
import withMeta from "../withMeta";
import IconComponent from "./IconComponent";
import IconWidgetPropertyCategories from "./IconWidgetPropertyCategories";

export default class IconWidget extends BaseWidget<
  IconWidgetProps,
  WidgetState
> {
  constructor(props: IconWidgetProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<IconWidgetProps>[]
    | undefined {
    return IconWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      icon: VALIDATION_TYPES.ICONS,
      color: VALIDATION_TYPES.TEXT,
    };
  }
  getPageView() {
    const { componentWidth, componentHeight } = getComponentDimensions(
      this.props,
    );
    const isAutoWidth = isFitContent(this.props.width.mode);
    const isAutoHeight = isFitContent(this.props.height.mode);
    let size = Math.min(componentWidth, componentHeight);

    if (isAutoWidth && !isAutoHeight) {
      size = componentHeight;
    } else if (!isAutoWidth && isAutoHeight) {
      size = componentWidth;
    }
    size = size - WIDGET_PADDING * 2;

    return (
      <IconComponent
        widgetId={this.props.widgetId}
        icon={this.props.icon}
        color={this.props.color}
        onClick={
          isActionHandlerSet(this.props.onClick) ? this.onClick : undefined
        }
        isLoading={this.props.animateLoading && this.props.isLoading}
        size={size}
      />
    );
  }
  onClick() {
    if (this.props.onClick) {
      super.runEventHandlers({
        steps: this.props.onClick,
        type: EventType.ON_CLICK,
        additionalNamedArguments: {},
      });
    }
  }

  getWidgetType(): WidgetType {
    return "ICON_WIDGET";
  }
}

export interface IconWidgetProps extends WidgetPropsRuntime {
  icon: string;
  color: string;
  onClick?: MultiStepDef;
  isVisible?: boolean;
  animateLoading: boolean;
}

const mapStateToProps = (state: AppState, ownProps: IconWidgetProps) => {
  return {
    // overwrite onClick from ownProps with the one from redux
    // TODO: investigate why ownProps.onClick doesn't match with data received from DSL (ex. inside Grid images)
    onClick:
      getWidgetPropertiesById(state, ownProps.widgetId)?.onClick ||
      ownProps.onClick,
  };
};

export const ConnectedIconWidget = connect(mapStateToProps)(
  withMeta(IconWidget),
);
