import { css } from "styled-components";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import { styleFromStyleBlock } from "./utils";

export const generateButtonStyles = (
  buttonStyles: GeneratedTheme["buttons"],
) => {
  return css`
    .${CLASS_NAMES.PRIMARY_BUTTON} {
      ${styleFromStyleBlock(buttonStyles.primary)}
    }
    .${CLASS_NAMES.SECONDARY_BUTTON} {
      ${styleFromStyleBlock(buttonStyles.secondary)}
    }
    .${CLASS_NAMES.TERTIARY_BUTTON} {
      ${styleFromStyleBlock(buttonStyles.tertiary)}
    }
    .${CLASS_NAMES.SYSTEM_BUTTON} {
      ${styleFromStyleBlock(buttonStyles.system)}
    }
    .${CLASS_NAMES.SECONDARY_NEUTRAL_BUTTON} {
      ${styleFromStyleBlock(buttonStyles.secondaryNeutral)}
    }
  `.join("");
};
