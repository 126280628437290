import React from "react";
import { connect } from "react-redux";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import {
  WidgetType,
  ImageAlign,
  CanvasLayout,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { type AppState } from "store/types";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { isActionHandlerSet } from "../shared";
import withMeta from "../withMeta";
import ImageComponent from "./ImageComponent";
import ImageWidgetPropertyCategories from "./ImageWidgetPropertyCategories";

export default class ImageWidget extends BaseWidget<
  ImageWidgetProps,
  WidgetState
> {
  constructor(props: ImageWidgetProps) {
    super(props);
    this.onImageClick = this.onImageClick.bind(this);
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<ImageWidgetProps>[]
    | undefined {
    return ImageWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      image: VALIDATION_TYPES.TEXT,
      imageShape: VALIDATION_TYPES.TEXT,
      defaultImage: VALIDATION_TYPES.TEXT,
      maxZoomLevel: VALIDATION_TYPES.NUMBER,
      borderRadius: VALIDATION_TYPES.TEXT,
      fillContainer: VALIDATION_TYPES.BOOLEAN,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }
  getPageView() {
    return (
      <ImageComponent
        disableDrag={(disable: boolean) => {
          this.disableDrag(disable);
        }}
        widgetId={this.props.widgetId}
        imageUrl={this.props.image || ""}
        onClick={
          isActionHandlerSet(this.props.onClick) ? this.onImageClick : undefined
        }
        align={this.props.align}
        showHoverPointer={
          this.props.appMode === APP_MODE.PUBLISHED ||
          this.props.appMode === APP_MODE.PREVIEW
        }
        defaultImageUrl={this.props.defaultImage}
        isLoading={this.props.isLoading}
        borderRadius={this.props.borderRadius}
        fillContainer={this.props.fillContainer}
        backgroundColor={this.props.backgroundColor}
        roundHeightToGrid={
          (this.props.parentLayout ?? CanvasLayout.FIXED) === CanvasLayout.FIXED
        }
        height={this.props.height}
        width={this.props.width}
        border={this.props.border}
      />
    );
  }

  onImageClick() {
    if (this.props.onClick) {
      super.runEventHandlers({
        steps: this.props.onClick,
        type: EventType.ON_CLICK,
        additionalNamedArguments: {},
      });
    }
  }

  getWidgetType(): WidgetType {
    return "IMAGE_WIDGET";
  }
}

type ImageShape = "RECTANGLE" | "CIRCLE" | "ROUNDED";

export interface ImageWidgetProps extends WidgetPropsRuntime {
  image: string;
  backgroundColor?: string;
  imageShape: ImageShape;
  defaultImage: string;
  maxZoomLevel: number;
  onClick?: MultiStepDef;
  align?: ImageAlign;
  borderRadius: string;
  fillContainer: boolean;
  isVisible?: boolean;
}

const mapStateToProps = (state: AppState, ownProps: ImageWidgetProps) => {
  return {
    // overwrite onClick from ownProps with the one from redux
    // TODO: investigate why ownProps.onClick doesn't match with data received from DSL (ex. inside Grid images)
    onClick:
      getWidgetPropertiesById(state, ownProps.widgetId)?.onClick ||
      ownProps.onClick,
  };
};

export const ConnectedImageWidget = connect(mapStateToProps)(
  withMeta(ImageWidget),
);
